<div class="asset-list-container" fxLayout="column" fxFill>
  <ng-container *ngIf="loadComplete() | async; else loadingSkeletons">
    <h3 data-e2e-asset-list class="asset-list-title">{{listTitle}} <span *ngIf="assetCount > 0" style="font-size: 60%"> {{assetCountResults}} </span ></h3>
    <div class="sorting-select" *ngIf="assetCount > 0" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="0.3rem">
      <mat-label class="sorting-select-label">{{ translateService.get(translations.appAssetList.sortingSelectLabel) | async | translate }}</mat-label>
      <mat-form-field appearance="none">
        <mat-select [formControl]="sortingControl" class="sorting-select-control">
          <mat-option *ngFor="let option of sortingOptions" [value]="option" class="sorting-select-option">
            {{option.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <cdk-virtual-scroll-viewport
      [itemSize]="vsItemSize" 
      [minBufferPx]="vsMinBufferPx" 
      [maxBufferPx]="vsMaxBufferPx"
      class="asset-list-scroll-container"
    >
      <app-asset-list-item 
        *cdkVirtualFor="let asset of assets; trackBy:trackFn; templateCacheSize:80" 
        [asset]="asset"
        (hoverOver)="hoverOver.emit(asset)"
        (hoverOut)="hoverOut.emit()" 
        [routerLink]="['/assets', asset.assetId, 'live']"
      >
      </app-asset-list-item>
      <p *ngIf="assetCount === 0" class="no-results-text">{{ translateService.get(translations.appAssetList.noResultsMsg) | async | translate }}</p>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <ng-template #loadingSkeletons>
    <cdk-virtual-scroll-viewport [itemSize]='vsItemSize' minBufferPx='vsMinBufferPx' maxBufferPx='vsMaxBufferPx'
      class='asset-list-scroll-container'>
      <div *ngFor='let i of [].constructor(skeletonMaxCount);'>
        <app-asset-list-item-skeleton></app-asset-list-item-skeleton>
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-template>
</div>