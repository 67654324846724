import { Injectable } from '@angular/core';
import { Asset, AssetsParams, GetAssetsResult } from '@app/modules/location-client/location-api.models';
import { LocationApiService } from '@app/modules/location-client/location-api.service';
import { deduplicateArray, extractIds, intersection } from '@app/modules/shared/utilities/utilities';
import { DivisionMappingService } from '@app/services/division-mapping.service';
import { DivisionCacheService } from '@app/services/DivisionCacheService';
import { PermissionsService } from '@zonar-ui/auth';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, expand, reduce, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DataDogService } from './data-dog.service';

@Injectable({
  providedIn: 'root'
})
export class LiveAssetQueryService {
  constructor(
    private locationApi: LocationApiService,
    private permissions: PermissionsService,
    private divisionMapper: DivisionMappingService, // For external users to fetch divisions
    private divisionCache: DivisionCacheService, // for Internal
    private store: Store,
    private datadog: DataDogService
  ) {}

  executeAssetQueryWithParams(params: AssetsParams): Observable<Array<Asset>> {
    return this.permissions.getIsZonarUser().pipe(
      switchMap(isZonarUser => {
        if (isZonarUser) {
          return this.fetchAssetsForInternalUser(params);
        } else {
          return this.fetchAssetsForCustomerUser(params);
        }
      }),
      switchMap(results => {
        return this.createResultsFromAPIRequests(results);
      }),
      catchError((error, _) => {
        return EMPTY;
      })
    );
  }

  private createResultsFromAPIRequests(results) {
    const resultSet = new Map<string, Asset>();
    // If we only had to make a single paginated request flow
    // to the Location API, then we do not need to flatten the array of arrays.
    if (!Array.isArray(results[0])) {
      results.forEach(item => {
        if (!resultSet.has(item.assetId)) {
          resultSet.set(item.assetId, item);
        }
      });
    } else {
      // Otherwise, we flatten.
      results.forEach(res =>
        res.map((item: Asset) => {
          if (!resultSet.has(item.assetId)) {
            resultSet.set(item.assetId, item);
          }
        })
      );
    }
    return of(Array.from(resultSet.values()));
  }

  /**
   * Returns the set of API results | Assets based on parameters for Zonar users
   * @param params
   */
  fetchAssetsForInternalUser(params): Observable<any[]> {
    const isFilteredByLocation = params.divisionIds?.length;
    const isFilteredByAccount = params.accountDivisions?.length;

    if (isFilteredByLocation) {
      const divParams = { ...params, divisionIds: params.divisionIds };
      return this.fetchPaginatedAssets(divParams);
    } else if (isFilteredByAccount) {
      // Otherwise, we recursively traverse the divisions
      const accounts = params.accountDivisions.map(d => this.divisionCache.divisions.find(account => account.id === d));
      const accountIds = accounts.map(a => a.id);
      const children = accounts.map(a => a.children).reduce((curr, acc) => curr.concat(acc), []);
      const dedupedParentsAndChildren = deduplicateArray([...accountIds, ...children]);
      const divParams = { ...params, divisionIds: dedupedParentsAndChildren };
      return this.fetchPaginatedAssets(divParams);
    } else {
      // Or failing any parameters we will grab all the things based on the params.
      return this.fetchPaginatedAssets(params);
    }
  }

  /**
   * Returns the set of API results | Assets based on parameters for customer users
   * @param params
   */
  fetchAssetsForCustomerUser(params): Observable<any[]> {
    const isFiltered = params?.divisionIds?.length || params.accountDivisions?.length;

    if (isFiltered) {
      const divisionsToFetch = this.getDivisionsToFetch(params.divisionIds, params.accountDivisions);
      const deduplicatedDivisionsAndChildren = deduplicateArray(divisionsToFetch);
      const divParams = { ...params, divisionIds: deduplicatedDivisionsAndChildren };
      return this.fetchPaginatedAssets(divParams);
    } else {
      return this.fetchPaginatedAssets(params);
    }
  }

  /**
   * Performs pagination flow based on parameters and headers. Will fetch from Location API
   * until there is no longer a next page token
   * @param params
   */

  private fetchPaginatedAssets(params: AssetsParams): Observable<Array<Asset>> {
    const assetsTiming = this.datadog.newRumTiming('paginated_assets_loaded', false);
    return this.locationApi.getAssets(params).pipe(
      expand(res => {
        if (res.nextPageToken && res.nextPageToken !== '') {
          return this.locationApi.getAssets({ ...params, nextPageToken: res.nextPageToken });
        } else {
          return EMPTY as Observable<GetAssetsResult>;
        }
      }),
      tap({
        complete: () => {
          this.datadog.sendRumTiming(assetsTiming);
        }
      }),
      reduce((acc: Asset[], res) => acc.concat(res.assets), []),
      catchError((err, _) => EMPTY)
    );
  }

  /**
   *
   * @param paramDivisions
   * @param accountDivisions
   * @return list of divisions to fetch in paginated division flow.
   */
  getDivisionsToFetch(paramDivisions?: string[], accountDivisions?: string[]): string[] {
    // This is fun!! first if we have account divisions(account code selected
    if (paramDivisions?.length && accountDivisions?.length) {
      const availableAccounts = accountDivisions;
      const childDivisionIds = extractIds(this.divisionMapper.getChildDivisionsForParentIds(accountDivisions));
      // This is essentially a check against whether the user is location locked. the first case is the false case.
      if (availableAccounts.length) {
        return [...availableAccounts, ...intersection(paramDivisions, childDivisionIds)];
      } else {
        // User is location locked, only fetch for children
        return [...intersection(paramDivisions, childDivisionIds)];
      }
    } else if (paramDivisions?.length) {
      // if just specified divisions, get all specified divisions and their children
      const paramChildren = extractIds(this.divisionMapper.getChildDivisionsForParentIds(paramDivisions));
      return [...paramDivisions, ...paramChildren];
    } else if (accountDivisions?.length) {
      // If we only specify an account to filter, get all authorized accounts and their children
      const children = extractIds(this.divisionMapper.getChildDivisionsForParentIds(accountDivisions));
      const allowedAccounts = extractIds(this.divisionMapper.getDivisionsForIds(accountDivisions)); // if loc locked, this will be empty
      return [...allowedAccounts, ...children];
    } else {
      // this actually should never happen, but logically TS doesn't know this.
      return [];
    }
  }
}
