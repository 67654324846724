import { Injectable } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ServerOfflineService {
  constructor(private notifications: NotificationService, private facade: LocationFacade) {}

  showNotificationForOfflineStatus() {
    window.addEventListener('offline', () => {
      this.notifications.openPoorNetworkConnectionNotification();
      this.facade.stopAssetsPolling();
    });
  }

  refreshAppForOnlineStatus() {
    window.addEventListener('online', () => {
      this.notifications.dismiss();
      this.facade.startAssetsPolling();
    });
  }
}
