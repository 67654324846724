import { EntityResource, FilterArgs } from '@app/store/filters/models/filters.model';
import { createAction, props } from '@ngrx/store';
import { SortingState } from '@app/store/layout/reducers/layout.reducer';
import { Zone } from '@app/modules/zones/zones.model';

export const enum FilterActionTypes {
  SET_COMPANY = '[Filters] Set Company Filter',
  CLEAR_COMPANY = '[Filters] Clear Company Filter',
  SET_DIVISIONS = '[Filters] Set Divisions Filter',
  CLEAR_DIVISIONS = '[Filters] Clear Divisions Filter',
  SET_LOCATIONS = '[Filters] Set Locations Filter',
  CLEAR_LOCATIONS = '[Filters] Clear Locations Filter',
  REMOVE_FILTER = '[Filters] Remove Filter Chip',
  CLEAR_FILTERS = '[Filters] Clear Filters',
  APPLY_FILTERS = '[Filters] Apply Filters',
  APPLY_SORTING = '[Filters] Apply Sorting',
  SET_DEFAULT_FILTER = '[Filters] Set Default Filter',
  SET_CURRENT_COMPANY = '[Filters] Set Current Company',
  SET_ZONE = '[Filters] Set Zone Filter',
  CLEAR_ZONE = '[Filters] Clear Zone Filter'
}

export const setCompany = createAction(FilterActionTypes.SET_COMPANY, props<{ company: string }>());
export const clearCompany = createAction(FilterActionTypes.CLEAR_COMPANY);

export const setDivisions = createAction(FilterActionTypes.SET_DIVISIONS, props<{ divisions: string[] }>());
export const clearDivisions = createAction(FilterActionTypes.CLEAR_DIVISIONS);

export const setLocations = createAction(FilterActionTypes.SET_LOCATIONS, props<{ locations: string[] }>());
export const clearLocations = createAction(FilterActionTypes.CLEAR_LOCATIONS);

export const removeFilter = createAction(FilterActionTypes.REMOVE_FILTER, props<{ filter: EntityResource }>());

export const clearFilters = createAction(FilterActionTypes.CLEAR_FILTERS);

export const applyFilters = createAction(FilterActionTypes.APPLY_FILTERS, props<{ filterArgs: FilterArgs }>());

export const applySorting = createAction(FilterActionTypes.APPLY_SORTING, props<{ sorting: SortingState }>());

export const setDefaultFilter = createAction(FilterActionTypes.SET_DEFAULT_FILTER, props<{ filterArgs }>());

export const setCurrentCompany = createAction(
  FilterActionTypes.SET_CURRENT_COMPANY,
  props<{ company: EntityResource }>()
);

export const setZone = createAction(FilterActionTypes.SET_ZONE, props<{ zone: Zone }>());
export const clearZone = createAction(FilterActionTypes.CLEAR_ZONE);
