import { Injectable } from '@angular/core';
import { NotificationType, NotificationAction, ZonarUiNotificationsService } from '@zonar-ui/notifications';

export interface NotificationConfig {
  type: NotificationType;
  title: string;
  text: string;
  durationInSeconds: number;
  action?: NotificationAction;
  buttonClassName?: string;
  hideButton?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  pendoErrorClass = '_pendo-error-notification';

  locationApiErrorNotificationConfig = {
    type: NotificationType.Error,
    title: 'Live vehicle data is not available',
    text: 'All vehicle data will be updated as soon as possible',
    durationInSeconds: 0,
    buttonClassName: this.pendoErrorClass,
    hideButton: true
  };

  poorNetworkConnectionNotificationConfig = {
    type: NotificationType.Error,
    title: 'Poor Network Connection Detected',
    text: '\xa0',
    durationInSeconds: 0,
    buttonClassName: this.pendoErrorClass,
    hideButton: true
  };

  constructor(private zuiNotificationService: ZonarUiNotificationsService) {}

  openLocationApiErrorNotification() {
    this.open(this.locationApiErrorNotificationConfig);
  }

  openPoorNetworkConnectionNotification() {
    this.open(this.poorNetworkConnectionNotificationConfig);
  }

  open(notificationConfig: NotificationConfig): void {
    const { type, title, text, durationInSeconds, action, buttonClassName, hideButton } = notificationConfig;
    this.zuiNotificationService.open(type, title, text, durationInSeconds, action, buttonClassName, hideButton);
  }

  dismiss(): void {
    this.zuiNotificationService.dismiss();
  }
}
