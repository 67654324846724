<div [id]="mapId" class="map-container three-control-slot"
    [ngClass]="(showIncidentButton | async) === true ? 'three-control-slot' : 'two-control-slot'">

    <div leaflet #mapEl *ngIf="(settingsLoadState | async) === loadSuccessful" [leafletOptions]="newOptions"
        (leafletMapReady)="onMapReady($event)" (leafletMapZoomEnd)="onMapZoomEnd($event)"></div>

    <app-map-options *ngIf="(settingsLoadState | async) === loadSuccessful" 
        position="topright"
        [map]="leafletService.map" 
        [isMapOptionsMenuOpen]="isMapOptionsMenuOpen"
        (toggleMapOptionsMenuEvent)="toggleMapOptionsMenu($event)"
        [layer]="layer" 
        (layerChange)="onLayerChange($event)"
        [checkboxesVisible]="mapOptionsCheckboxesVisible" 
        [clusterEnabled]="clusterEnabled"
        (clusterEnabledChange)="onClusterEnabledChange($event)" 
        [incidentsEnabled]="incidentsEnabled"
        (incidentsEnabledChange)="onIncidentsEnabledChange($event)" 
        [trafficEnabled]="trafficEnabled"
        (trafficEnabledChange)="onTrafficEnabledChange($event)" 
        [zonesEnabled]="zonesEnabled"
        (zonesEnabledChange)="onZonesEnabledChange($event)"
        [classicLayerSelected]="classicLayerSelected"
        [satelliteLayerSelected]="satelliteLayerSelected"
    >
    </app-map-options>

    <app-incident-control *ngIf="(showIncidentButton | async) === true" position="topright"
        [map]="leafletService.map"></app-incident-control>

    <app-search-control *ngIf="(settingsLoadState | async) === loadSuccessful" position="topright"
        [map]="leafletService.map"
        (zoneResultClicked)="onSearchControlZoneClicked()"></app-search-control>

    <app-timeline-control></app-timeline-control>

    <leaflet-locate-control [options]="(locateControlOptions | async)"
        [map]="leafletService.map"></leaflet-locate-control>

    <leaflet-fullscreen-control [options]="(fullscreenControlOptions | async)"
        [map]="leafletService.map"></leaflet-fullscreen-control>
</div>