import { Injectable } from '@angular/core';
import { Asset } from '@app/modules/location-client/location-api.models';
import { ExtendedPathInput, PathInput, PathSpeedUnits } from '@app/modules/location/models/path-api.model';
import { mockAsset } from '@app/store/asset/mocks/location-client';
import { environment } from '@environments/environment';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { take } from 'rxjs/operators';
import { UiUtilities } from '@app/services/ui-utilities';

@Injectable({
  providedIn: 'root'
})
export class AssetIoService {
  constructor(
    private translateService: TranslateService,
    private translations: Translations,
    private uiUtils: UiUtilities
  ) {
    this.translateService
      .get([
        this.translations.assetIoService.auxInputsActive,
        this.translations.assetIoService.active,
        this.translations.assetIoService.stops
      ])
      .pipe(take(1))
      .subscribe(translated => (this.translated = translated));
  }

  translated;
  // asset IOs are active when the asset.inputs value is false
  activeIOValue: boolean = false;
  // set useMockAsset to true to override input asset with mock data
  useMockAsset: boolean = false;

  public hasIoInputs(asset: Asset): boolean {
    return this.getActiveIoCount(this.useMockAsset ? mockAsset : asset) > 0;
  }

  private getActiveIoCount(asset: Asset): number {
    const workingAsset = this.useMockAsset ? mockAsset : asset;

    if (!workingAsset.inputs) {
      return 0;
    }

    let count = 0;
    if (workingAsset.inputs) {
      Object.keys(workingAsset.inputs).forEach(key => {
        if (workingAsset.inputs[key] === this.activeIOValue) {
          count += 1;
        }
      });
    }
    return count;
  }

  public getBadgeText(asset: Asset): string {
    const workingAsset = this.useMockAsset ? mockAsset : asset;

    if (!workingAsset || !workingAsset.inputs || !workingAsset.labels) {
      return;
    }

    const count = this.getActiveIoCount(workingAsset);

    if (count === 0) {
      return;
    }

    if (count > 1) {
      return `${count} ${this.translated[this.translations.assetIoService.auxInputsActive]}`;
    }

    for (const key of Object.keys(workingAsset.inputs)) {
      if (workingAsset.inputs[key] === this.activeIOValue) {
        return `${workingAsset.labels[key]} ${this.translated[this.translations.assetIoService.active]}`;
      }
    }
  }

  public getAssetIOsDetail(asset: Asset): string[] {
    const workingAsset = this.useMockAsset ? mockAsset : asset;

    if (!workingAsset || !workingAsset.inputs || !workingAsset.labels) {
      return;
    }

    return Object.entries(workingAsset.labels)
      .sort((a, b) => {
        return a[0] > b[0] ? 1 : -1;
      })
      .reduce((acc, cur) => {
        const [key, label] = cur;
        const value = workingAsset.inputs[key];
        return value === this.activeIOValue ? [...acc, label] : acc;
      }, []);
  }

  public formatPoweredOnDuration(seconds, meters) {
    return `${this.uiUtils.formatDuration(seconds * 1000, false)}  \u2022  ${this.uiUtils.formatDistance(meters)}`;
  }

  public formatPoweredOffDuration(seconds, stops) {
    return `${this.uiUtils.formatDuration(seconds * 1000, false)}  \u2022  ${stops} ${
      this.translated[this.translations.assetIoService.stops]
    }`;
  }

  public getIOString(inputs: ExtendedPathInput[], delimiter = ', '): string {
    // Is it not possible for a points to have NO inputs? Not sure, but will defensively code against that anyway
    if (!inputs?.length) {
      return null;
    }
    const activeInputs = inputs.filter(i => {
      return i.change;
    });

    if (!activeInputs.length) {
      return null;
    }

    return activeInputs.map(io => io.label).join(delimiter);
  }

  public assetIoFeatureEnabled(): boolean {
    return environment.featureFlags.assetIOs;
  }

  public formatPathIos(inputs: { [key: string]: PathInput }[]): ExtendedPathInput[] {
    if (!inputs) {
      return [];
    }

    return inputs.map(io => {
      const label = Object.keys(io)[0];
      const value = io[label].value;
      const pin = io[label].pin;
      const change = io[label].change;
      return { label, value, pin, change };
    });
  }
}
