<app-progress-spinner
    class="desktop-main-app-spinner"
    [class.mobile]="(locationFacade.getIsMobile() | async) === true"
    *ngIf="loading"
>
</app-progress-spinner>

<app-desktop-sidebar >
    <ng-container>
        <app-map-header class="map-header" (filterClick)="handleOpenFilterDialog.emit()"></app-map-header>
        <app-asset-list 
            fxFlex 
            class="content-pane"
            (hoverOver)="locationFacade.setHover($event)" 
            (hoverOut)="locationFacade.clearHover()"
        ></app-asset-list>
    </ng-container>
</app-desktop-sidebar>
