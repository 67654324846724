<ng-container *ngIf="(locationFacade.getIsMobile() | async) === true; then  mobileBlock else desktopBlock;"></ng-container>

<ng-template #desktopBlock>
    <app-map 
        id="map-base" 
        (map$)="locationFacade.receiveMap($event)"
    ></app-map>
</ng-template>

<ng-template #mobileBlock>
    <app-map 
        id="map-base" 
        class="content-pane mobile-map-pane"
        (map$)="locationFacade.receiveMap($event)"
    ></app-map>
</ng-template>