import { Asset, AssetsParams } from '@app/modules/location-client/location-api.models';
import { Location } from '@app/core/models/zonar-accounts.model';
import { IUser } from '@zonar-ui/auth/lib/models/user.model';
import { IUserProfile, IUserProfileRole } from '@zonar-ui/auth/lib/models/user-profile.model';
import { mockIoInputs, mockIoLabels } from '@app/modules/asset-io/asset-io.mock';
import { mockMixedOpenEvents } from '@app/modules/events/events.mock';
import { EStatus, ETenantType } from '@zonar-ui/auth';

export const mockAsset: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lew',
  legacyAccount: 'tes1234',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 57,
  odometer: 89274,
  powerOn: true,
  heading: 149,
  idling: false,
  geoEventTs: new Date().toISOString(),
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  externalId: '2415',
  legacyAttributes: {
    gpssn: '21000001'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: 'FOO-BAR',
    powerUnitNumber: 'N4893'
  },
  make: 'Ford',
  assetName: 'ASSET_HD149',
  driverProfileId: 'e600b066-ff9c-42f8-886b-8584637bcd6f',
  driverEventTs: '2016-08-29T09:12:33.001Z',
  driverChangeSource: 'mock name',
  openEvents: mockMixedOpenEvents,
  connectionIssue: false
};

export const mockAssetIdling: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: '3ea02845-6c4e-48af-81e3-d488a11f7a50',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lex',
  legacyAccount: 'dbr47_rep1023',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 0,
  odometer: 89274,
  powerOn: true,
  heading: 148.7,
  idling: true,
  geoEventTs: new Date().toISOString(),
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '18000022'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: '',
    powerUnitNumber: 'N4893'
  },
  make: 'Ford',
  assetName: 'ASSET_IDLING',
  connectionIssue: false
};

export const mockViewableIdling = {
  ...mockAssetIdling,
  distanceFromSelectedAsset: 0,
  iconUrl: null,
  className: null,
  subTitle: 'Idling',
  sidebarMessage: '0.0 mi. away',
  markerSubtitle: '0.0 mi. away'
};

export const mockAssetStopped: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7ley',
  legacyAccount: 'dbr47_rep1023',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 0,
  odometer: 89274,
  powerOn: false,
  heading: 148.7,
  idling: false,
  geoEventTs: new Date().toISOString(),
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '8000011'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: '',
    powerUnitNumber: 'N4893'
  },
  assetName: 'ASSET_STOPPED',
  make: 'Ford',
  connectionIssue: false
};

export const mockViewableStopped = {
  ...mockAssetStopped,
  distanceFromSelectedAsset: 0,
  iconUrl: null,
  className: null,
  subTitle: 'Powered Off',
  sidebarMessage: '0.0 mi. away',
  markerSubtitle: '0.0 mi. away'
};

export const mockAssetZtrak: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lez',
  legacyAccount: 'dbr47_rep1023',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 0,
  odometer: null,
  powerOn: null,
  heading: null,
  idling: null,
  geoEventTs: new Date().toISOString(),
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '3000000010'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: '',
    powerUnitNumber: 'N4893'
  },
  assetName: 'ZTRAK_ASSET',
  make: 'Zonar',
  connectionIssue: false
};

export const mockViewableZtrak = {
  ...mockAssetZtrak,
  distanceFromSelectedAsset: 0,
  iconUrl: null,
  className: null,
  subTitle: 'zTrak',
  sidebarMessage: '0.0 mi. away',
  markerSubtitle: '0.0 mi. away'
};

export const mockAssetNoLocation: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lfa',
  legacyAccount: 'dbr47_rep1023',
  latitude: null,
  longitude: null,
  speed: 0,
  odometer: 89274,
  powerOn: false,
  heading: 148.7,
  idling: false,
  geoEventTs: null,
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '8104900'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: '',
    powerUnitNumber: 'N4893'
  },
  assetName: 'ASSET_STOPPED',
  make: 'Ford',
  connectionIssue: false
};

export const mockViewableNoLocation = {
  ...mockAssetNoLocation,
  distanceFromSelectedAsset: 7607.862502749497,
  iconUrl: null,
  className: null,
  subTitle: 'MISSING GPS DATA',
  sidebarMessage: '7608 mi. away',
  markerSubtitle: '7608 mi. away'
};

export const mockAssetsParams: AssetsParams = {
  companyId: 'mockCompanyId',
  divisionId: 'mockDivisionId',
  assetIds: [],
  powerOn: true,
  ztrak: false,
  idling: true,
  minSpeed: 0,
  maxSpeed: 100,
  sortBy: 'assetName',
  sortOrder: 'asc',
  pageSize: 150
};

export const mockAssetsResponse = [mockAssetIdling, mockAssetStopped, mockAssetZtrak, mockAssetNoLocation];
export const mockViewableAssetsResponse = [
  mockViewableIdling,
  mockViewableStopped,
  mockViewableZtrak,
  mockViewableNoLocation
];

export const mockLocationApiResponse = { assets: mockAssetsResponse, nextpageToken: '' };

export const mockAddress = {
  street: 'mockStreet',
  city: 'mockCity',
  state: 'mockState',
  zip: 'mockZip',
  country: 'mockCountry',
  county: 'mockCounty'
};

export const mockPosition = {
  lat: 123,
  lon: 456
};

export const mockUser: IUser = {
  id: 'mockUserId',
  identityProviderId: 'auth0|mockUser',
  lastName: 'User',
  firstName: 'Mock',
  email: 'foo@bar.com',
  created: new Date(),
  modified: new Date()
};

export const mockLocation: Location = {
  id: mockAsset.homeLocationId,
  name: 'mockName',
  address: mockAddress,
  timezone: 'mockTimezone',
  position: mockPosition
};

export const mockUserProfileRole: IUserProfileRole = {
  id: 'mockUserProfileRoleId',
  name: 'mockUserProfileRole',
  productId: 'mockUserProfileProductId',
  permissions: ['perm1', 'perm2']
};

export const mockUserProfile: IUserProfile = {
  id: 'mockUserProfile',
  companyEmail: 'mock@email.tld',
  status: EStatus.ACTIVE,
  userId: 'mockUser',
  employeeNumber: 'mockEmployeeNumber',
  homeLocationId: 'mockHomeLocation',
  applicationId: '0961d523-ef76-489a-8613-e0e639d5b233',
  companyId: 'mockCompanyId',
  divisions: ['mockDivision'],
  roles: [mockUserProfileRole]
};
export const mockUserProfileAllDivs: IUserProfile = {
  id: 'mockUserProfileAllDivs',
  companyEmail: 'mock@email.tld',
  status: EStatus.ACTIVE,
  userId: 'mockUser',
  employeeNumber: 'mockEmployeeNumber',
  homeLocationId: 'mockHomeLocation',
  applicationId: '0961d523-ef76-489a-8613-e0e639d5b233',
  companyId: 'mockCompanyId',
  divisions: [],
  roles: [mockUserProfileRole]
};
export const mockZonarUserProfile: IUserProfile = {
  id: 'mockZonarUserProfile',
  companyEmail: 'mock@email.tld',
  status: EStatus.ACTIVE,
  userId: 'mockUser',
  employeeNumber: 'mockEmployeeNumber',
  homeLocationId: 'mockHomeLocation',
  applicationId: '0961d523-ef76-489a-8613-e0e639d5b233',
  companyId: null,
  divisions: [],
  roles: [mockUserProfileRole]
};

export const mockUserProfile1: IUserProfile = {
  id: 'mockUserProfile1',
  companyEmail: 'mock1@email.tld',
  status: EStatus.ACTIVE,
  userId: 'mockUser1',
  employeeNumber: 'mockEmployeeNumber1',
  homeLocationId: 'mockHomeLocation',
  applicationId: '0961d523-ef76-489a-8613-e0e639d5b233',
  companyId: 'mockCompanyId',
  divisions: ['mockDivision'],
  roles: [mockUserProfileRole]
};

export const mockUserProfile2: IUserProfile = {
  id: 'mockUserProfile12',
  companyEmail: 'mock2@email.tld',
  status: EStatus.ACTIVE,
  userId: 'mockUser2',
  employeeNumber: 'mockEmployeeNumber2',
  homeLocationId: 'mockHomeLocation',
  applicationId: '0961d523-ef76-489a-8613-e0e639d5b233',
  companyId: 'mockCompanyId',
  divisions: ['mockDivision'],
  roles: [mockUserProfileRole]
};

export const mockAssetWithDriverProfileId: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  driverProfileId: '0066c8f7-b9f3-451c-bff7-0ba103e9296a',
  driverFirstName: 'Dev',
  driverLastName: 'Test',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lew',
  legacyAccount: 'tes1234',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 57,
  odometer: 89274,
  powerOn: true,
  heading: 149,
  idling: false,
  geoEventTs: '2016-08-29T09:12:33.001Z',
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '21000001'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: 'FOO-BAR',
    powerUnitNumber: 'N4893'
  },
  assetName: 'ASSET_HD149'
};
export const mockAssetWithoutDriverFirstName: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  driverProfileId: '0066c8f7-b9f3-451c-bff7-0ba103e9296a',
  driverFirstName: null,
  driverLastName: 'Test',
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lew',
  legacyAccount: 'tes1234',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 57,
  odometer: 89274,
  powerOn: true,
  heading: 149,
  idling: false,
  geoEventTs: '2016-08-29T09:12:33.001Z',
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '21000001'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: 'FOO-BAR',
    powerUnitNumber: 'N4893'
  },
  assetName: 'ASSET_HD149'
};
export const mockAssetWithoutDriverLastName: Asset = {
  companyId: 'asjkdf88-6c54-8du7-90e6-d701748f0851',
  divisionId: 'sk8765fdk-ld0s-4b01-9kdu-d701748f0851',
  driverProfileId: '0066c8f7-b9f3-451c-bff7-0ba103e9296a',
  driverFirstName: 'Dev',
  driverLastName: null,
  assetId: 'd290f1ee-6c54-i9s8-90e6-lkd98dhg7lew',
  legacyAccount: 'tes1234',
  latitude: 47.6395,
  longitude: -120.6825,
  speed: 57,
  odometer: 89274,
  powerOn: true,
  heading: 149,
  idling: false,
  geoEventTs: '2016-08-29T09:12:33.001Z',
  inputs: { ...mockIoInputs },
  labels: { ...mockIoLabels },
  ecmConnected: true,
  homeLocationId: '9f5d0228-f33e-4b8c-8aa4-b8c986a39860',
  legacyAttributes: {
    gpssn: '21000001'
  },
  assetTypeInfo: {
    vin: '5N3ZA0NE6AN906847',
    licenseNumber: 'FOO-BAR',
    powerUnitNumber: 'N4893'
  },
  assetName: 'ASSET_HD149'
};

export const mockGroupPolicyResponse = {
  managedEntity: {
    managedSourceId: null,
    managedSource: null,
    externallyManaged: false
  },
  groupId: '6e44d2a3-1882-407b-973c-df894c7c1432',
  name: 'Auto-generated Group name 29a83515fc729790ba1e81daa0ff8217',
  description: null,
  jobType: 'STANDARD',
  status: EStatus.ACTIVE,
  user: {
    created: '2024-02-12T23:42:32.659994',
    modified: '2024-04-23T18:12:31.784164',
    managedEntity: {
      managedSourceId: null,
      managedSource: null,
      externallyManaged: false
    },
    firstName: 'Test',
    middleName: null,
    lastName: 'User',
    email: 'grouppolicyreferenceuser@example.test',
    credentialType: 'STANDARD',
    id: 'c2d55c7d-231d-44e3-8955-218769936627',
    identityProviderId: 'auth0|661984bdd3c1ad003ba736c4',
    identityProviderIds: ['auth0|661984bdd3c1ad003ba736c4'],
    defaultLanguage: 'en-US',
    status: EStatus.ACTIVE,
    auditInfo: {
      userId: null,
      clientId: 'pDhCU4NrcNYyx376Qa5GLO54EVEHKSi2@clients',
      transactionId: null,
      groupId: null
    }
  },
  tenant: {
    created: '2024-04-23T18:27:11.734693',
    modified: '2024-04-23T18:27:11.734700',
    managedEntity: {
      managedSourceId: null,
      managedSource: null,
      externallyManaged: false
    },
    id: '61d2a80d-c92e-4b85-9965-97616579a931',
    name: 'Group Policy Reference Tenant',
    status: EStatus.ACTIVE,
    scope: {
      type: ETenantType.SINGLE_COMPANY,
      companies: [
        {
          id: 'user-profile-company-id',
          name: 'Group Policy Reference Company QA',
          shortName: null,
          legacyAccountCodes: []
        }
      ],
      divisions: []
    },
    auditInfo: {
      userId: null,
      clientId: 'pDhCU4NrcNYyx376Qa5GLO54EVEHKSi2@clients',
      transactionId: null,
      groupId: null
    }
  },
  policy: {
    created: '2024-04-23T18:16:40.827859',
    modified: '2024-04-23T18:21:12.981666',
    managedEntity: {
      managedSourceId: null,
      managedSource: null,
      externallyManaged: false
    },
    id: 'e71bf4d8-5ab1-42ef-aea5-d7b25652c8b8',
    name: 'Company Admin 2',
    companyId: 'user-profile-company-id',
    templateId: null,
    grants: [
      {
        application: {
          id: '0f62b543-ecf8-464e-ae6e-5b79f981de6f',
          name: 'GTCx Maps',
          description: 'See the location and activity of your fleet in real-time',
          identityProviderId: 'RzbT6zbqstLZKVI4Sv6Kugd0w51NKdiA'
        },
        roles: [
          {
            id: '1871d70a-9dbd-4cb1-914f-c7d57236b6a6',
            name: 'User',
            description: 'Can view all assets on maps.',
            restricted: false,
            translationId: null,
            productId: null,
            permissions: ['gtcx_maps:read:assets', 'gtcx_maps:read:company']
          },
          {
            id: '1b4ecfdb-b857-44e3-9867-7c2bd2f8e53b',
            name: 'Admin',
            description: 'Manage many aspects of your fleet, including routes, zones, home locations, and much more.',
            restricted: false,
            translationId: null,
            productId: null,
            permissions: ['gtcx_maps:read:assets', 'gtcx_maps:update:assets', 'gtcx_maps:read:company']
          },
          {
            id: '8e205df4-72a6-4de2-b4f4-b76d65177888',
            name: 'Manage Assets',
            description: 'Can create & edit assets; including attributes, custom fields, input labels, and subtypes',
            restricted: false,
            translationId: null,
            productId: null,
            permissions: ['gtcx_maps:read:assets', 'gtcx_maps:update:assets']
          }
        ]
      }
    ],
    status: EStatus.ACTIVE,
    auditInfo: {
      userId: '93ab8351-14a5-4dbf-b170-cd6db7b2aca6',
      clientId: 'samlp|Duo|david.snowberger@zonarsystems.com',
      transactionId: null,
      groupId: null
    }
  },
  lastUpdated: '2024-04-25T06:00:04.076093'
};
