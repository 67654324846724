import { createReducer, on } from '@ngrx/store';
import * as LayoutActions from '../actions/layout.actions';

export const layoutFeatureKey = 'layout';

export enum ViewContext {
  DETAILS = 'details',
  LIST = 'list',
  MOBILE_MAP = 'mobileMap',
  SPLIT_VIEW = 'splitView'
}

export enum DetailsSubcontext {
  LIVE = 'live',
  HISTORY = 'history'
}

export interface ViewState {
  context: ViewContext;
  subContext: DetailsSubcontext;
  filtersOpen: boolean;
  paneOpen: boolean;
}

export interface SortingState {
  sortKey: string;
  sortAsc: boolean;
}

export interface LayoutState {
  view: ViewState;

  // TODO: determine if the `assetList` slice of state is being used anywhere in the app.
  //  there is no selector for it, so i don't think so :(
  assetList: {
    sorting: SortingState;
  };
}

export const initialState: LayoutState = {
  view: {
    context: ViewContext.LIST,
    subContext: DetailsSubcontext.LIVE,
    filtersOpen: false,
    paneOpen: true
  },
  assetList: {
    sorting: {
      sortKey: 'name',
      sortAsc: true
    }
  }
};

export const reducer = createReducer(
  initialState,

  on(LayoutActions.setAssetListSorting, (state, { sorting }) => {
    return { ...state, assetList: { ...state.assetList, sorting: { ...sorting } } };
  }),

  on(LayoutActions.setViewContext, (state, { context }) => {
    return { ...state, view: { ...state.view, context } };
  }),

  on(LayoutActions.setViewSubContext, (state, { subContext }) => {
    return { ...state, view: { ...state.view, subContext } };
  }),

  on(LayoutActions.setViewFiltersOpen, (state, { filtersOpen }) => {
    return { ...state, view: { ...state.view, filtersOpen } };
  }),

  on(LayoutActions.setViewPaneOpen, (state, { paneOpen }) => {
    return { ...state, view: { ...state.view, paneOpen } };
  }),

  on(LayoutActions.toggleViewPaneOpen, state => {
    const paneOpen = !state.view.paneOpen;
    return { ...state, view: { ...state.view, paneOpen } };
  })
);
