import { Division, GetCompanyDivisionsParams, GetCompanyParams } from '@app/core/models/zonar-accounts.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { DropdownOption } from '@zonar-ui/searchable-dropdown';
import { Zone } from '@app/modules/zones/zones.model';

export enum EntityType {
  COMPANY = 'Company',
  DIVISION = 'Division',
  LOCATION = 'Location',
  TOGGLE = 'Toggle',
  DRIVER = 'Driver',
  SEARCH_TERM = 'Search Term',
  ZONE = 'Zone'
}

export enum ToggleFilterType {
  POWER_ON = 'Powered On'
}

export interface ToggleFilterEvent {
  toggled: boolean;
  filter: string;
}

export interface EntityResource {
  id: string;
  name: string;
  type: EntityType;
  legacyType?: string;
  legacyLocationsForLimitedUser?: { id: string; name: string }[];
}

export type SortOrder = 'asc' | 'desc';
export type SortAttribute = 'assetName' | 'geoEventTs' | 'driverName';

export interface FilterArgs {
  powerOn: boolean;
  sortOrder: string;
  sortAttribute: string;
  search: string;
  company: string;
  divisions: string[];
  locations: string[];
  driver: string;
  searchTerms: string[];
  zone: EntityResource;
}

export interface Filter {
  driver: EntityResource;
  powerOn: boolean;
  company: EntityResource;
  divisions: EntityResource[];
  locations: EntityResource[];
  sortOrder: SortOrder;
  sortAttribute: SortAttribute;
  search: string;
  defaultFilterSet: boolean;
  searchTerms: string[];
  zone: EntityResource;
}

export const initialFilterState: Filter = {
  powerOn: false,
  company: null,
  driver: null,
  divisions: [],
  locations: [],
  sortOrder: 'asc',
  sortAttribute: 'assetName',
  search: '',
  searchTerms: [],
  defaultFilterSet: false,
  zone: null
};

export interface FiltersState {
  companies: DropdownOption[];
  companiesLoadingState: ResourceLoadState;
  companiesError: string;
  companiesParams: GetCompanyParams;
  divisions: Division[];
  divisionsLoadingState: ResourceLoadState;
  divisionsError: string;
  divisionsParams: GetCompanyDivisionsParams;
  locations: Division[];
  locationsLoadingState: ResourceLoadState;
  locationsError: string;
  locationsParams: GetCompanyDivisionsParams;
  filter: Filter;
  defaultFilter: Filter;
  assetIds: string[];
  showFilterChipsForLimitedUser?: boolean;
  zone: Zone;
}
