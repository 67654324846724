import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, of, Subject, first } from 'rxjs';
import { distinctUntilChanged, filter, pairwise, switchMap, take, takeUntil } from 'rxjs/operators';
import { control, Control, FullscreenOptions, latLng, Map, MapOptions, TileLayer, ZoomAnimEvent } from 'leaflet';
import 'src/app/modules/shared/components/map/controls/view-entire-trip-control/view-entire-trip-control.js';
import 'src/app/modules/shared/components/map/controls/follow-asset-control/follow-asset-control.js';
import 'src/app/modules/shared/components/map/controls/timeline-control/timeline-control.js';
import { LeafletService } from '@app/modules/location/services/leaflet.service';
import {
  LeafletConfigService,
  GTCxTileLayerOptions
} from '@app/modules/location/services/leaflet-layer-configs/leaflet-config.service';
// we need this polyfill for Safari < v13 or else we get reference error for ResizeObserver
import { ResizeObserver } from 'resize-observer';
import { SettingsApiService } from '@app/services/settings-api.service';
import { MapSettings, SettingsMap, defaultMapCoordinates } from '@app/modules/location/models/settings.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { environment as env } from '@environments/environment';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';
import { HereTrafficService } from '@app/modules/location/services/here-traffic.service';
import { MAP_OPTIONS_TILE_LAYER_NAMES } from './controls/map-options-menu/map-options-menu.model';
import { EventTrackingFacade, TrackedEvents } from '@app/modules/location/facade/event-tracking.facade';
import { DataDogService } from '@app/services/data-dog.service';

declare module 'leaflet' {
  interface Control {
    _addTo(map: Map): Control;
  }

  interface Map {
    _leaflet_id: number;
    _container: HTMLElement;
  }
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnDestroy, OnInit {
  constructor(
    public leafletService: LeafletService,
    private leafletConfig: LeafletConfigService,
    public platformFacade: PlatformFacade,
    private settingsService: SettingsApiService,
    public featureToggleService: FeatureToggleService,
    private leafletZoneService: LeafletZoneService,
    private hereTrafficService: HereTrafficService,
    private router: Router,
    private pendo: EventTrackingFacade,
    private datadogService: DataDogService
  ) {
    this.platformFacade
      .getIsMobile()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(flag => (this.isMobile = flag === true ? true : false));
    this.featureToggleService
      .isFeatureEnabled('maps-incident-button-enabled')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(featureFlag => {
        this.incidentButtonEnabled = featureFlag === true ? true : false;
        this.showIncidentButton.next(this.incidentButtonEnabled && !this.isMobile);
      });

    this.settingsService.userSettingsCache$
      .pipe(
        filter((userSettingsCache: SettingsMap) => {
          return userSettingsCache.has(MapSettings.MAP_ZONES);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(userSettingsCache => {
        const mapZoneSettings = userSettingsCache.get(MapSettings.MAP_ZONES);
        this.zonesEnabled = mapZoneSettings.value === 'true';
      });
  }

  // TODO: is this @ViewChild still used by anything?
  @ViewChild('mapEl', { static: true }) public mapElement: ElementRef;

  @Output() map$: EventEmitter<Map> = new EventEmitter();
  @Output() zoom$: EventEmitter<number> = new EventEmitter();
  @Input() mapId = 'map-container';

  public map: Map;
  public zoom: number;
  private onDestroy$ = new Subject();
  public locateControlOptions = new BehaviorSubject<Control.LocateOptions>(
    this.leafletConfig.defaultLocateControlOptions
  );
  public fullscreenControlOptions = new BehaviorSubject<FullscreenOptions>(
    this.leafletConfig.defaultFullscreenControlOptions
  );
  public settingsLoadState = new BehaviorSubject<ResourceLoadState>(ResourceLoadState.INITIAL);
  public loadSuccessful = ResourceLoadState.LOAD_SUCCESSFUL;
  public region = env.region;
  public newOptions: MapOptions = this.leafletConfig.defaultMapOptions;
  public isMobile = false;
  public incidentButtonEnabled = true;
  public showIncidentButton = new BehaviorSubject<boolean>(false);
  private mapResizeObserver = new ResizeObserver(() => {
    this.map?.invalidateSize();
  });

  trafficOverlay = this.hereTrafficService.getTrafficOverlay();

  layer: TileLayer = this.leafletConfig.tileLayers.NORMAL_DAY;
  mapOptionsCheckboxesVisible: boolean = false;
  clusterEnabled: boolean;
  incidentsEnabled: boolean;
  trafficEnabled: boolean;
  zonesEnabled: boolean;

  isMapOptionsMenuOpen = false;
  settingsMap;
  $url = new BehaviorSubject<string>(this.router.url);

  classicLayerSelected: boolean = false;
  satelliteLayerSelected: boolean = false;

  clearLayersHistoryView() {
    this.leafletService.clusterLayer.disableClustering();
    this.leafletService.handleIncidentOverlayRemove();
    this.trafficOverlay.removeFrom(this.map);
  }

  onLayerChange(newLayer: string) {
    const tempLayer: TileLayer = this.leafletConfig.tileLayers[newLayer];

    this.setBorderOnSelectedLayer(tempLayer);

    if (tempLayer == this.layer) return;
    this.map.addLayer(tempLayer);
    this.map.removeLayer(this.layer);
    this.layer = tempLayer;
    this.settingsService.saveSetting(MapSettings.MAP_LAYER, newLayer).pipe(take(1)).subscribe();
  }

  onClusterEnabledChange(enabled: boolean) {
    this.clusterEnabled = enabled;
    if (enabled) {
      this.leafletService.clusterLayer.enableClustering();
      this.settingsService.saveSetting(MapSettings.MAP_CLUSTERING, 'true').pipe(take(1)).subscribe();
    } else {
      this.leafletService.clusterLayer.disableClustering();
      this.settingsService.saveSetting(MapSettings.MAP_CLUSTERING, 'false').pipe(take(1)).subscribe();
    }
  }

  onIncidentsEnabledChange(enabled: boolean) {
    this.incidentsEnabled = enabled;
    if (enabled) {
      this.leafletService.handleIncidentOverlayAdd();
      this.settingsService.saveSetting(MapSettings.MAP_INCIDENTS, 'true').pipe(take(1)).subscribe();
    } else {
      this.leafletService.handleIncidentOverlayRemove();
      this.settingsService.saveSetting(MapSettings.MAP_INCIDENTS, 'false').pipe(take(1)).subscribe();
    }
  }

  onTrafficEnabledChange(enabled: boolean) {
    this.trafficEnabled = enabled;
    if (enabled) {
      this.trafficOverlay.addTo(this.map);
      this.settingsService.saveSetting(MapSettings.MAP_TRAFFIC, 'true').pipe(take(1)).subscribe();
    } else {
      this.trafficOverlay.removeFrom(this.map);
      this.settingsService.saveSetting(MapSettings.MAP_TRAFFIC, 'false').pipe(take(1)).subscribe();
    }
  }

  onZonesEnabledChange(enabled: boolean) {
    // enabled arg to be used when we take off 'zones-enabled' feature flag
    this.zonesEnabled = enabled;
    if (enabled) {
      this.leafletZoneService.addZonesToMap(this.map);
      this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'true').pipe(take(1)).subscribe();
    } else {
      this.leafletZoneService.clearZoneFeatureGroup();
      this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'false').pipe(take(1)).subscribe();
    }
  }

  setFeatureGroupsFromSettings(settingsMap: any) {
    // Cluster Assets
    if (settingsMap.has(MapSettings.MAP_CLUSTERING)) {
      const clusterSetting = settingsMap.get(MapSettings.MAP_CLUSTERING).value === 'true' ? true : false;
      this.onClusterEnabledChange(clusterSetting);
    }

    // Road Incidents
    if (settingsMap.has(MapSettings.MAP_INCIDENTS)) {
      const incidentsSetting = settingsMap.get(MapSettings.MAP_INCIDENTS).value === 'true' ? true : false;
      this.onIncidentsEnabledChange(incidentsSetting);
    }

    // Traffic
    if (settingsMap.has(MapSettings.MAP_TRAFFIC)) {
      const trafficSetting = settingsMap.get(MapSettings.MAP_TRAFFIC).value === 'true' ? true : false;
      this.onTrafficEnabledChange(trafficSetting);
    }

    // Zones
    if (settingsMap.has(MapSettings.MAP_ZONES)) {
      // TODO: comment this back in when we are done with DevCycle featuretoggle in https://zonarsystems.atlassian.net/browse/ZTT-3012

      // const zonesSetting = settingsMap.get(MapSettings.MAP_ZONES).value === 'true' ? true : false;
      // this.onZonesEnabledChange(zonesSetting);

      this.featureToggleService
        .isFeatureEnabled('zones-enabled')
        .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe(areZonesEnabled => {
          if (areZonesEnabled) {
            const zonesSetting = settingsMap.get(MapSettings.MAP_ZONES).value === 'true' ? true : false;
            this.onZonesEnabledChange(zonesSetting);
          } else {
            this.onZonesEnabledChange(areZonesEnabled as boolean);
          }
        });
    }
  }

  ngOnInit() {
    this.leafletConfig
      .getTranslatedControlOptions$('locate')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(options => {
        this.locateControlOptions.next(options);
      });

    this.leafletConfig
      .getTranslatedControlOptions$('fullscreen')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(options => {
        this.fullscreenControlOptions.next(options);
      });

    this.settingsLoadState.next(ResourceLoadState.LOADING);
    this.settingsService
      .getAllSettings()
      .pipe(
        switchMap(settingsMap => {
          this.settingsMap = settingsMap;
          if (settingsMap.has(MapSettings.MAP_LAYER)) {
            const layerSetting = settingsMap.get(MapSettings.MAP_LAYER);
            // eslint-disable-next-line no-unused-expressions
            Object.keys(this.leafletConfig.tileLayers).includes(layerSetting.value)
              ? (this.newOptions.layers = [this.leafletConfig.tileLayers[layerSetting.value]])
              : (this.newOptions.layers = [this.leafletConfig.tileLayers.NORMAL_DAY]);

            this.setBorderOnSelectedLayer(this.newOptions.layers[0] as TileLayer);
            this.layer = this.newOptions.layers[0] as TileLayer;
          }
          return of(settingsMap);
        })
      )
      .subscribe(_ => {
        this.settingsLoadState.next(ResourceLoadState.LOAD_SUCCESSFUL);
      });
  }

  setBorderOnSelectedLayer(layer: TileLayer) {
    const layerName = (layer.options as GTCxTileLayerOptions).name;

    if (!layerName) {
      throw new Error('setBorderOnSelectedLayer layer has no name');
    }

    if (layerName === MAP_OPTIONS_TILE_LAYER_NAMES.CLASSIC) {
      this.classicLayerSelected = true;
      this.satelliteLayerSelected = false;
    } else {
      this.satelliteLayerSelected = true;
      this.classicLayerSelected = false;
    }
  }

  ngOnDestroy(): void {
    this.map?.clearAllEventListeners();
    this.map?.remove();

    this.mapResizeObserver.disconnect();

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onMapReady(map: Map) {
    this.map = map;
    this.map$.emit(map);
    this.zoom = map.getZoom();
    this.zoom$.emit(this.zoom);
    this.map.setView(latLng(defaultMapCoordinates[this.region].lat, defaultMapCoordinates[this.region].long));

    // send pendo track event for whether Zones are enabled on map load
    if (this.settingsMap.has(MapSettings.MAP_ZONES)) {
      const pendoEventName =
        this.settingsMap.get(MapSettings.MAP_ZONES).value === 'true'
          ? TrackedEvents.ZONES_ENABLED_ON_APP_LOAD
          : TrackedEvents.ZONES_DISABLED_ON_APP_LOAD;
      this.pendo.trackEvent(pendoEventName);
    }

    // set visibility of feature groups from settings
    // this must happen after we emit map to leaflet service since presently the cluster layer is built in the receiveMap function
    this.setFeatureGroupsFromSettings(this.settingsMap);

    // set up a subscription comparing the current url w/ previous url
    this.$url.pipe(pairwise(), takeUntil(this.onDestroy$)).subscribe(urls => {
      // if we are in history view, hide checkboxes and disable some feature groups
      if (urls[1].includes('/history')) {
        this.mapOptionsCheckboxesVisible = false;
        this.clearLayersHistoryView();

        // otherwise, check if we just came from history to any other url - if so, restore from settings
      } else if (urls[0].includes('/history')) {
        this.mapOptionsCheckboxesVisible = true;
        this.setFeatureGroupsFromSettings(this.settingsMap);
        // else make sure checkboxes are showing for initial load in live view
      } else {
        this.mapOptionsCheckboxesVisible = true;
      }
    });

    // since pairwise above needs two events to emit, push again so it fires on app load
    this.$url.next(this.router.url);

    // pipe navigation changes to the above to make it work
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe(_ => {
        const url = this.router.url;
        this.$url.next(url);
      });

    // add attribution to map
    this.leafletConfig
      .getTranslatedControlOptions$('attribution')
      .pipe(take(1))
      .subscribe(translated => {
        control.attribution().addAttribution(translated).addTo(this.map);
      });

    // remove the attribution prefix (leaflet and flag links)
    this.map.attributionControl?.setPrefix(false);

    // add scale control to map
    control.scale(this.leafletConfig.getLocalizedScaleOptions()).addTo(this.map);

    // add zoom control to map
    this.leafletConfig
      .getTranslatedControlOptions$('zoom')
      .pipe(take(1))
      .subscribe(options => {
        control.zoom(options).addTo(this.map);
      });

    const mapContainer = document.getElementById(this.mapId);
    if (mapContainer) {
      this.mapResizeObserver.observe(mapContainer);
    }
    this.featureToggleService
      .isFeatureEnabled('rum-context-dump-enabled')
      .pipe(
        filter(isFeatureEnabled => Boolean(isFeatureEnabled)),
        switchMap(_ => {
          return this.datadogService.getRumContextDumpData();
        })
      )
      .subscribe(contextDumpData => {
        const { currentCompany, divisions, divisionsCount } = contextDumpData;
        delete contextDumpData.currentCompany;
        delete contextDumpData.divisions;
        delete contextDumpData.divisionsCount;
        this.datadogService.addRumAction('context_dump', contextDumpData);
        this.datadogService.setContextProperty('companyDivisions', {
          company: currentCompany,
          divisions,
          divisionsCount
        });
      });
  }

  onMapZoomEnd(e: ZoomAnimEvent) {
    this.zoom = e.target.getZoom();
    this.zoom$.emit(this.zoom);
  }

  toggleMapOptionsMenu(event) {
    this.isMapOptionsMenuOpen = event;
  }

  onSearchControlZoneClicked() {
    if (!this.zonesEnabled) this.onZonesEnabledChange(true);
  }
}
