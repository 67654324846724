import {
  EventSeverity,
  EventStatus,
  GetEventsResult,
  GTCxEventData,
  OpenEvent
} from '@app/modules/location-client/location-api.models';
import { EventDetails } from '@app/modules/events/events.model';

export const mockEmptyEvents: GTCxEventData[] = [];

export const mockSingleEvent: GTCxEventData[] = [
  {
    id: 'event zero',
    assetId: 'asset 1',
    dataType: 'gtcx_event',
    location: null,
    severity: EventSeverity.CRITICAL,
    startTime: '2022-11-18 12:10:00',
    jsonData: {
      id: 'event data zero',
      productId: 'foo',
      dataType: 'foo bar',
      parentId: 'fiz bug',
      startTime: '2022-11-18 12:00:00',
      endTime: '2022-11-18 12:08:00',
      title: 'mock single event whose title is way too long and should be truncated',
      description: 'the event description',
      severity: EventSeverity.CRITICAL,
      eventStatus: EventStatus.OPEN,
      urlName: 'a url name?',
      location: null,
      metadata: [
        { key: 'key_1', label: 'Key 1 Label', value: 'Key 1  value' },
        { key: 'key_2', label: 'Key 2 Label', value: 'Key 2  value' }
      ],
      multimedia: null
    },
    openEventInsertTs: '2022-11-18 12:00:01'
  }
];

export const mockTwoCriticalEvents: GTCxEventData[] = [
  {
    ...mockSingleEvent[0]
  },
  {
    ...mockSingleEvent[0],
    id: 'event one',
    jsonData: {
      ...mockSingleEvent[0].jsonData,
      id: 'event data one',
      title: 'mock event 1'
    }
  }
];

export const mockMixedEvents: GTCxEventData[] = [
  ...mockTwoCriticalEvents,
  {
    ...mockSingleEvent[0],
    id: 'event two',
    severity: EventSeverity.WARNING,
    jsonData: {
      ...mockSingleEvent[0].jsonData,
      id: 'event data two',
      severity: EventSeverity.WARNING,
      title: 'mock warning event two'
    }
  },
  {
    ...mockSingleEvent[0],
    id: 'event three',
    severity: EventSeverity.WARNING,
    jsonData: {
      ...mockSingleEvent[0].jsonData,
      id: 'event data three',
      severity: EventSeverity.WARNING,
      title: 'mock warning event three'
    }
  }
];

export const mockTwoWarningEvents: GTCxEventData[] = [
  {
    ...mockSingleEvent[0],
    id: 'warning event zero',
    severity: EventSeverity.WARNING,
    jsonData: {
      ...mockSingleEvent[0].jsonData,
      id: 'warning event data zero',
      severity: EventSeverity.WARNING
    }
  },
  {
    ...mockSingleEvent[0],
    id: 'warning event one',
    severity: EventSeverity.WARNING,
    jsonData: {
      ...mockSingleEvent[0].jsonData,
      id: 'warning event data one',
      severity: EventSeverity.WARNING
    }
  }
];

export const mockGetEventsResult: GetEventsResult = {
  events: mockMixedEvents,
  nextPageToken: null
};

export const mockOpenEventDetails: EventDetails[] = [
  {
    title: mockMixedEvents[0].jsonData.title,
    timeStamp: 'thiswillbereplacedbyspec',
    details: [
      {
        displayLabel: mockMixedEvents[0].jsonData.metadata[0].label,
        value: mockMixedEvents[0].jsonData.metadata[0].value
      },
      {
        displayLabel: mockMixedEvents[0].jsonData.metadata[1].label,
        value: mockMixedEvents[0].jsonData.metadata[1].value
      }
    ]
  },
  {
    title: mockMixedEvents[1].jsonData.title,
    timeStamp: 'thistoowillbereplaced',
    details: [
      {
        displayLabel: mockMixedEvents[1].jsonData.metadata[0].label,
        value: mockMixedEvents[1].jsonData.metadata[0].value
      },
      {
        displayLabel: mockMixedEvents[1].jsonData.metadata[1].label,
        value: mockMixedEvents[1].jsonData.metadata[1].value
      }
    ]
  }
];

export const mockMixedOpenEvents: OpenEvent[] = [
  {
    id: mockMixedEvents[0].id,
    title: mockMixedEvents[0].jsonData.title,
    productId: mockMixedEvents[0].jsonData.productId,
    description: mockMixedEvents[0].jsonData.description,
    severity: mockMixedEvents[0].severity,
    eventTimeStamp: mockMixedEvents[0].startTime
  },
  {
    id: mockMixedEvents[1].id,
    title: mockMixedEvents[1].jsonData.title,
    productId: mockMixedEvents[1].jsonData.productId,
    description: mockMixedEvents[1].jsonData.description,
    severity: mockMixedEvents[1].severity,
    eventTimeStamp: mockMixedEvents[1].startTime
  },
  {
    id: mockMixedEvents[2].id,
    title: mockMixedEvents[2].jsonData.title,
    productId: mockMixedEvents[2].jsonData.productId,
    description: mockMixedEvents[2].jsonData.description,
    severity: mockMixedEvents[2].severity,
    eventTimeStamp: mockMixedEvents[2].startTime
  },
  {
    id: mockMixedEvents[3].id,
    title: mockMixedEvents[3].jsonData.title,
    productId: mockMixedEvents[3].jsonData.productId,
    description: mockMixedEvents[3].jsonData.description,
    severity: mockMixedEvents[3].severity,
    eventTimeStamp: mockMixedEvents[3].startTime
  }
];
