import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimersService {
  intervalTimer(callback, interval) {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    return setInterval(callback, interval);
  }
}
