import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

import {
  AssetsParams,
  GetAssetsResult,
  Asset,
  GetEventsResult
} from '@app/modules/location-client/location-api.models';

@Injectable({
  providedIn: 'root'
})
export class LocationApiService {
  constructor(private http: HttpClient) {}

  getAssets(params: AssetsParams): Observable<GetAssetsResult> {
    // assetIds cannot be specified with companyId nor divisionId
    if (params?.assetIds?.length) {
      delete params.companyId;
      delete params.divisionId;
    }
    const url = `${environment.locationApi.url}/assets`;
    const accessControlHeader = new HttpHeaders().set('Access-Control-Max-Age', '86400');
    return this.http.post(url, params, { headers: accessControlHeader }) as Observable<GetAssetsResult>;
  }

  getSelectedAssetById(assetId: string): Observable<Asset> {
    const url = `${environment.locationApi.url}/assets/${assetId}`;
    return this.http.get(url) as Observable<Asset>;
  }

  getOpenEventsByAssetId(assetId: string, params = null): Observable<GetEventsResult> {
    const url = `${environment.locationApi.url}/events/${assetId}`;
    return this.http.get(url, { params }) as Observable<GetEventsResult>;
  }
}
