export enum SettingsOwnerType {
  COMPANY = 'COMPANY',
  USERPROFILE = 'USERPROFILE',
  USER = 'USER'
}

export enum SettingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum MapSettings {
  MAP_SORT_ORDER = 'GTCx Maps Fleet List Sort Order',
  MAP_LAYER = 'GTCx Maps Tile Preference',
  MAP_CLUSTERING = 'GTCx Maps Cluster Layer Visibility',
  MAP_INCIDENTS = 'GTCx Maps Road Incidents Layer Visibility',
  MAP_TRAFFIC = 'GTCx Maps Traffic Layer Visibility',
  MAP_ZONES = 'GTCx Maps Zones Layer Visibility'
}

export interface SettingInfo {
  id: string;
  companyId?: string;
  divisionId?: string;
  applicationId?: string;
  ownerId: string;
  ownerType: SettingsOwnerType;
  name: string;
  value: string;
  status?: SettingStatus;
  created?: string;
  modified?: string;
}

export type SettingsMap = Map<MapSettings, SettingInfo>;

export interface SettingParams {
  companyId?: string;
  divisionId?: string;
  applicationId?: string;
  ownerId?: string;
  ownerType?: SettingsOwnerType;
  name?: string;
  value?: string;
  status?: SettingStatus;
}

export const defaultLocalStorageSettings: SettingInfo[] = [
  {
    id: 'X',
    ownerId: 'X',
    ownerType: SettingsOwnerType.USERPROFILE,
    name: 'GTCx Maps Cluster Layer Visibility',
    value: 'true'
  },
  {
    id: 'X',
    ownerId: 'X',
    ownerType: SettingsOwnerType.USERPROFILE,
    name: 'GTCx Maps Fleet List Sort Order',
    value: 'ASSET_DESC'
  },
  {
    id: 'X',
    ownerId: 'X',
    ownerType: SettingsOwnerType.USERPROFILE,
    name: 'GTCx Maps Tile Preference',
    value: 'NORMAL_DAY'
  },
  {
    id: 'X',
    ownerId: 'X',
    ownerType: SettingsOwnerType.USERPROFILE,
    name: 'GTCx Maps Road Incidents Layer Visibility',
    value: 'false'
  },
  {
    id: 'X',
    ownerId: 'X',
    ownerType: SettingsOwnerType.USERPROFILE,
    name: 'GTCx Maps Traffic Layer Visibility',
    value: 'false'
  },
  {
    id: 'X',
    ownerId: 'X',
    ownerType: SettingsOwnerType.USERPROFILE,
    name: 'GTCx Maps Zones Layer Visibility',
    value: 'false'
  }
];

export const defaultMapCoordinates = {
  NA: {
    lat: 47.602004,
    long: -122.335295
  },
  EU: {
    lat: 49.356234,
    long: 5.969659
  }
};
