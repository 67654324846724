import { Component, OnInit } from '@angular/core';
import { LocationFacade } from '@app/modules/location/facade/location.facade';

@Component({
  selector: 'app-shared-map',
  templateUrl: './shared-map.component.html',
  styleUrls: ['./shared-map.component.scss']
})
export class SharedMapComponent {
  constructor(public locationFacade: LocationFacade) {}
}
