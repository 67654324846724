import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { environment } from '@environments/environment';
import { catchError, filter, tap } from 'rxjs/operators';
import { NotificationService } from '@app/services/notification.service';
import { isCancelledRequest } from '@app/modules/shared/utilities/utilities';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { retryWithBackoff } from '@zonar-ui/core';
import { DataDogService } from '@app/services/data-dog.service';

@Injectable()
export class FailedRequestInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private locationFacade: LocationFacade,
    private datadog: DataDogService
  ) {}

  private DELAY = 500;
  private MAX_RETRIES = 5;
  private MAX_BACKOFF = 60_000;

  private consecutiveFailedLocationApiCalls = 0;
  private consecutiveFailedLocationApiCallLimit = environment.liveUpdate.consecutiveFailureLimit;
  private restartPollingDelay = environment.liveUpdate.restartPollingDelay;
  private restartPollingTimerSubscription$: Subscription;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ignore for vdofleet api calls
    if (req.headers.get('vdo-service') != null) {
      return next.handle(req);
    }

    if (req.url.includes(`${environment.locationApi.url}/assets`)) {
      return this.handleLocationApiFailure(req, next);
    }

    return this.retryWithBackoff(req, next);
  }

  handleLocationApiFailure(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      filter(response => !isCancelledRequest(response)),
      tap(() => {
        this.notificationService.dismiss();
        this.cancelRestartAssetsPollingTimeout();
        this.consecutiveFailedLocationApiCalls = 0;
      }),
      catchError(err => {
        this.consecutiveFailedLocationApiCalls++;
        if (this.consecutiveFailedLocationApiCalls === this.consecutiveFailedLocationApiCallLimit) {
          this.notificationService.openLocationApiErrorNotification();
          this.stopAssetsPolling();
          this.restartAssetsPollingAfterDelay();

          this.consecutiveFailedLocationApiCalls = 0;
        }

        return throwError(err);
      })
    );
  }

  stopAssetsPolling(): void {
    this.locationFacade.stopAssetsPolling();
  }

  restartAssetsPollingAfterDelay(): void {
    this.cancelRestartAssetsPollingTimeout();
    this.restartPollingTimerSubscription$ = timer(this.restartPollingDelay).subscribe(() => {
      this.locationFacade.startAssetsPolling();
    });
  }

  cancelRestartAssetsPollingTimeout(): void {
    this.restartPollingTimerSubscription$?.unsubscribe();
  }

  retryWithBackoff(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(retryWithBackoff(this.DELAY, this.MAX_RETRIES, this.MAX_BACKOFF));
  }
}
