import { IOsInputs, IOsLabels } from './asset-io.models';
import * as moment from 'moment';
import { PathSummary } from '@app/modules/location/models/path-api.model';

/*
  Data Scenario 1
  inputs: {input5: null, input4: null, input3: null, input2: null, input1: null}
  labels: {input5: null, input4: null, input3: null, input2: null, input1: null}

  Data Scenario 2
  inputs: {input5: true, input4: true, input3: true, input2: true, input1: true}
  labels: {input5: 'ever', input4: 'president', input3: 'best', input2: 'trump', input1: 'donald'}
*/

export const mockIoInputs: IOsInputs = {
  input1: false,
  input2: false,
  input3: true,
  input4: true,
  input5: true
};

export const mockIoLabels: IOsLabels = {
  input1: 'dwayne the',
  input2: 'rock johnson',
  input3: 'best',
  input4: 'president',
  input5: 'ever'
};

export const mockSummary: PathSummary = {
  duration: 86400,
  avgSpeed: 19661,
  speedUnits: 'KM/H',
  startTime: new Date('2021-07-20T07:00:00+00:00'),
  endTime: new Date('2021-07-21T07:00:00+00:00'),
  distance: 54600,
  numberOfStops: 6,
  ioCounts: [
    { label: 'Door Open', count: 2 },
    { label: 'Stop Arm', count: 2 },
    { label: 'SC 3', count: 0 },
    { label: 'SC 4', count: 0 },
    { label: 'SC 5', count: 0 }
  ]
};

export const mockInputsFormatted = [
  { label: 'Door Open', value: true, pin: 'input1', change: true },
  { label: 'Stop Arm', value: true, pin: 'input2', change: true },
  { label: 'SC 3', value: false, pin: 'input3', change: false },
  { label: 'SC 4', value: false, pin: 'input4', change: false },
  { label: 'SC 5', value: false, pin: 'input5', change: false }
];
