import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Injector } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { SharedMapComponent } from '@app/modules/shared-map/shared-map/shared-map.component';

@Injectable({
  providedIn: 'root'
})

// solution for shared component instance directive taken from https://stackblitz.com/edit/angular-13dj9k?file=app%2Fhome.component.ts
export class SharedMapService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router
  ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SharedMapComponent);
    this.componentRef = componentFactory.create(this.injector);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.currentViewContainerRef) {
        this.detach(this.currentViewContainerRef);
      }

      if (event instanceof NavigationEnd && this.currentViewContainerRef) {
        this.attach(this.currentViewContainerRef);
      }
    });
  }

  componentRef: ComponentRef<SharedMapComponent>;

  currentViewContainerRef: ViewContainerRef;

  attach(viewContainerRef: ViewContainerRef) {
    this.currentViewContainerRef = viewContainerRef;
    viewContainerRef.insert(this.componentRef.hostView);
  }

  detach(viewContainerRef: ViewContainerRef) {
    viewContainerRef.detach(viewContainerRef.indexOf(this.componentRef.hostView));
  }
}
