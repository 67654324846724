import { Component, Input } from '@angular/core';

import { Asset } from '@app/modules/location-client/location-api.models';
import { AssetIoService } from '../../asset-io.service';

@Component({
  selector: 'app-asset-io-badge',
  templateUrl: './asset-io-badge.component.html',
  styleUrls: ['./asset-io-badge.component.scss']
})
export class AssetIoBadgeComponent {
  @Input() asset: Asset;

  constructor(public assetIoService: AssetIoService) {}
}
