import { AssetsParams } from '@app/modules/location-client/location-api.models';
import { environment } from '@environments/environment';
import { isBoolean } from '@app/modules/shared/utilities/utilities';
import { FiltersState } from '@app/store/filters/models/filters.model';

export const buildAssetsParams = (params: AssetsParams, filters?: FiltersState): AssetsParams => {
  const sharedParams = {
    pageSize: environment.liveUpdate.pageSize,
    sortOrder: filters?.filter?.sortOrder,
    sortAttribute: filters?.filter?.sortAttribute
  };
  // this must be a direct query for a specific asset
  if (params?.assetIds?.length) {
    return {
      ...sharedParams,
      assetIds: params.assetIds
    };
  }

  // this must be a direct query for a specific driver
  if (params?.driverProfileId) {
    const assetParams: AssetsParams = {
      ...sharedParams,
      driverProfileId: params.driverProfileId,
      companyId: params.companyId,
      active: true
    };
    if (params?.divisionId) {
      assetParams.divisionId = params.divisionId;
    }
    return assetParams;
  }

  const newAssetParams = <AssetsParams>{
    ...sharedParams,
    requireDevice: environment.locationApi.hasAssociatedDevice
  };
  newAssetParams.companyId = params?.companyId ? params.companyId : filters.filter?.company?.id;
  newAssetParams.active = params?.active ? params.active : true;
  delete newAssetParams.divisionIds;
  delete newAssetParams.accountDivisions;
  const divisionIds = filters.filter.locations.map(l => l.id);
  const accountDivisions = filters.filter.divisions.filter(l => l.legacyType === 'LEGACY').map(l => l.id);
  if (divisionIds.length) {
    newAssetParams.divisionIds = divisionIds;
  } else if (accountDivisions.length) {
    newAssetParams.accountDivisions = accountDivisions;
  }
  if (isBoolean(filters.filter?.powerOn) && filters.filter.powerOn) {
    newAssetParams.powerOn = filters.filter.powerOn;
  } else {
    delete newAssetParams.powerOn;
  }
  newAssetParams.sortOrder = filters.filter?.sortOrder ? filters.filter?.sortOrder : params.sortOrder;
  newAssetParams.sortAttribute = filters.filter?.sortAttribute ? filters.filter?.sortAttribute : params.sortAttribute;
  if (filters.filter?.driver?.id) {
    newAssetParams.driverProfileId = filters.filter.driver.id;
  }
  newAssetParams.searchTerms = filters.filter?.searchTerms;
  if (filters.filter.zone) {
    newAssetParams.geojson = JSON.stringify(filters.zone.geometry);
  }

  return newAssetParams;
};

export const objectShallowEquality = <Type>(previous: Type, next: Type): boolean => {
  // https://blog.bitsrc.io/how-to-compare-objects-in-javascript-f4eafef807fc
  let len = null;

  if (!previous || !next) {
    return false;
  }
  if (Object.keys(previous).length !== Object.keys(next).length) {
    return false;
  } else {
    len = Object.keys(previous).length;
  }
  let matches = 0;
  Object.keys(previous).forEach(key => {
    if (previous[key] === next[key]) {
      matches += 1;
    }
  });
  return matches === len;
};
