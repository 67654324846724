import { Injectable } from '@angular/core';

import { PermissionsService } from '@zonar-ui/auth';
import { catchError, switchMap } from 'rxjs/operators';
import { LocationApiService } from '@app/modules/location-client/location-api.service';
import { combineLatest, Observable, of } from 'rxjs';
import { Asset } from '@app/modules/location-client/location-api.models';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { DivisionMappingService } from '@app/services/division-mapping.service';
import { CoreCompanyApiService } from '@app/services/core-company-api.service';
import { DataDogService } from '@app/services/data-dog.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedAssetService {
  constructor(
    private permissions: PermissionsService,
    private locationApiSvc: LocationApiService,
    private locationFacade: LocationFacade,
    private divisionMappingService: DivisionMappingService,
    private coreCompanyApiService: CoreCompanyApiService,
    private ddService: DataDogService
  ) {}

  noHomeLocationMsg = 'Unable To Load';

  mergeAssetHomeLocationForZonarUser(companyId: string, locationId: string, asset: Asset): Observable<Asset> {
    if (locationId) {
      return this.coreCompanyApiService.getLocation(companyId, locationId).pipe(
        switchMap(response => {
          asset = {
            ...asset,
            homeLocation: response.body.name
          };
          return of(asset);
        }),
        catchError(error => {
          this.ddService.addRumError(new Error(error));
          return of({
            ...asset,
            homeLocation: this.noHomeLocationMsg
          });
        })
      );
    }

    return of({
      ...asset,
      homeLocation: this.noHomeLocationMsg
    });
  }

  mergeAssetWithLocationForNonZonarUser(locationId: string, asset: Asset): Observable<Asset> {
    if (locationId) {
      const homeLocation = this.divisionMappingService.getLocationNameById(locationId) || this.noHomeLocationMsg;

      return of({
        ...asset,
        homeLocation: homeLocation || this.noHomeLocationMsg
      });
    }

    return of({
      ...asset,
      homeLocation: this.noHomeLocationMsg
    });
  }

  getSelectedAssetById(assetId: string): Observable<Asset> {
    let _companyId = null;
    let _isZonarUser = null;
    return combineLatest([this.locationFacade.getAssetParams(), this.permissions.getIsZonarUser()]).pipe(
      switchMap(([assetParams, isZonarUser]) => {
        _companyId = assetParams?.companyId;
        _isZonarUser = isZonarUser;

        return this.locationApiSvc.getSelectedAssetById(assetId).pipe(
          switchMap(asset => {
            if (_isZonarUser) {
              return this.mergeAssetHomeLocationForZonarUser(_companyId, asset?.homeLocationId, asset);
            }
            return this.mergeAssetWithLocationForNonZonarUser(asset?.homeLocationId, asset);
          })
        );
      })
    );
  }
}
