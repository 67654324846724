import { IOsInputs, IOsLabels } from '@app/modules/asset-io/asset-io.models';
import { SortAttribute, SortOrder } from '@app/store/filters/models/filters.model';
import { LatLng } from 'leaflet';
import * as Events from 'events';

export enum DeviceType {
  ZTRAK = 'zTrak'
}

export interface LegacyAttributes {
  type?: string;
  subtype?: string;
  gpssn?: string;
}

export interface AssetTypeInfo {
  vin?: string;
  licenseNumber?: string;
  powerUnitNumber?: string;
  fuelType?: string;
}

// canonical doc for the V2 changes: https://zonarsystems.atlassian.net/wiki/spaces/DSPod/pages/137434431750/Current+Location+V2+Technical+Design+Document#API-Response-Changes
export interface Asset {
  companyId?: string;
  divisionId?: string;
  assetId: string;
  legacyAccount?: string;
  latitude?: number;
  longitude?: number;
  inputs?: IOsInputs;
  labels?: IOsLabels;
  ecmConnected?: boolean;
  speed?: number;
  powerOn?: boolean;
  heading?: number;
  odometer?: number;
  idling?: boolean;
  homeLocationId?: string;
  homeLocation?: string;
  assetName?: string;
  driverProfileId?: string;
  geoEventTs?: string;
  externalId?: string;
  legacyAttributes?: LegacyAttributes;
  assetTypeInfo?: AssetTypeInfo;
  make?: string;
  active?: boolean;
  exsid?: string;

  // Electric vehicle properties
  electricVehicle?: EvData;

  // Ztrak device properties
  batteryPct?: number;
  ztrakBatteryCondition?: number;

  openEvents?: OpenEvent[];
  driverFirstName?: string;
  driverLastName?: string;
  driverChangeSource?: string;
  driverEventTs?: string;
  connectionIssue?: boolean;
}
export interface EvData {
  stateOfCharge?: number;
}

export interface AssetsParams {
  divisionIds?: string[];
  // 'accountDivisions' is not a location-api param. however, in the live-asset-query-service, it is used to pass into
  // the service what account-level divisions need to be passed into the DivisionIds field
  accountDivisions?: Array<string>;
  assetIds?: Array<string>;
  ztrak?: boolean;
  requireDevice?: boolean;
  powerOn?: boolean;
  idling?: boolean;
  minSpeed?: number;
  maxSpeed?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
  sortAttribute?: SortAttribute; // this is used in maps code, but does not appear to be a location-api param
  circle?: Array<number>;
  polygon?: Array<number>;
  companyId?: string;
  divisionId?: string;
  legacyAccount?: string;
  driverProfileId?: string;
  pageSize?: number;
  nextPageToken?: string;
  detailedInfo?: boolean;
  active?: boolean;
  searchTerms?: Array<string>;
  geojson?: string;
}

export interface GetAssetsResult {
  assets?: Asset[];
  nextPageToken?: string;
}

// Asset Open Event model
export interface OpenEvent {
  id?: string;
  title?: string;
  productId?: string;
  description?: string;
  severity?: EventSeverity;
  eventTimeStamp?: string;
}

// GTCxEventData model

export enum EventSeverity {
  INFO = 3,
  WARNING = 2,
  CRITICAL = 1
}

export enum EventStatus {
  OPEN = 'open',
  CLOSED = 'closed',
  INSTANT = 'instant'
}

export interface EventMetadata {
  key: string;
  label: string;
  value?: any;
}

export interface EventMultimedia {
  url: string;
  created: string;
  contentType: string;
}

export interface GTCxData {
  id: string;
  productId: string;
  dataType: string;
  parentId: string;
  startTime: string;
  endTime: string;
  title: string;
  description: string;
  severity: EventSeverity; // 1 is high, 3 is low
  eventStatus: EventStatus;
  urlName: string;
  location: LatLng;
  metadata: EventMetadata[];
  multimedia: EventMultimedia[];
}

export interface GTCxEventData {
  id: string;
  assetId: string;
  dataType: string;
  location: LatLng;
  severity: EventSeverity;
  startTime?: string;
  jsonData?: GTCxData;
  openEventInsertTs: string;
}

export interface GetEventsResult {
  events?: GTCxEventData[];
  nextPageToken?: string;
}
