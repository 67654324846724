import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

import { AutocompleteParams, AutocompleteResult } from '@app/modules/autocomplete-client/autocomplete-api.models';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteApiService {
  constructor(private http: HttpClient) {}

  getAutocomplete(params: AutocompleteParams): Observable<AutocompleteResult> {
    const url = `${environment.autocompleteApi.url}`;
    params.hasAssociatedDevice = `${environment.locationApi.hasAssociatedDevice}`;
    return this.http.post<AutocompleteResult>(url, params);
  }
}
