// Angular
import { Injectable } from '@angular/core';
import { DivisionCacheService } from '@app/services/DivisionCacheService';

// RxJS
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';

// ngrx
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

// App
import { FiltersState, SortAttribute, SortOrder } from '@app/store/filters/models/filters.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { CoreCompanyApiService } from '@app/services/core-company-api.service';
import * as SearchFiltersActions from '@app/store/filters/actions/search-filters.actions';
import { PermissionsActionTypes } from '@app/store/permissions/actions/permissions.actions';
import * as fromFilters from '@app/store/filters/selectors/filters.selectors';
import * as fromSearchFilters from '@app/store/filters/selectors/search-filters.selector';
import { environment as env } from '@environments/environment';
import { SettingsApiService } from '@app/services/settings-api.service';
import { PermissionsService } from '@zonar-ui/auth';

@Injectable()
export class SearchFiltersEffects {
  constructor(
    private actions$: Actions<SearchFiltersActions.SearchFiltersActionsUnion>,
    private apiService: CoreCompanyApiService,
    private divisionCache: DivisionCacheService,
    private store: Store<FiltersState>,
    private settingsService: SettingsApiService,
    private permissionsService: PermissionsService
  ) {}

  getDivisions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchFiltersActions.SearchFiltersActionTypes.GET_DIVISIONS),
      switchMap(action => {
        return combineLatest([
          of(action),
          this.permissionsService.getIsPermissionsLoaded(),
          this.permissionsService.getDivisionMap(),
          this.permissionsService.getIsZonarUser(),
          this.permissionsService.getCurrentCompanyContext()
        ]);
      }),
      filter(([action, isPermsLoaded, divisionMap, isZonarUser, currentCompany]) => {
        return !!isPermsLoaded && !!Object.keys(divisionMap).length && !!currentCompany;
      }),
      concatMap(([action, isFetchPermsSuccess, divisionMap, isZonarUser, currentCompany]) => {
        const { params } = action;
        let { companyId } = action;

        // somewhere upstream, comapnyId is coming back an as object of { companyId: "some string"...} and that was too difficult to debug for the scope of ZTT-43, hence this check. This was also the source of why a multi-company user did not work in staging, but worked well ind ev
        companyId = typeof companyId === 'string' ? companyId : (companyId as any).id;

        if (!isZonarUser) {
          const divisions = [];
          const locations = [];
          Object.keys(divisionMap).forEach(divisionId => {
            const division = divisionMap[divisionId];
            if (division.companyId === currentCompany.id && division.type === 'LEGACY') {
              divisions.push(division);
            } else if (division.companyId == currentCompany.id && division.type === 'LEGACY_LOCATION') {
              locations.push(division);
            }
          });

          this.store.dispatch(SearchFiltersActions.saveSearchFiltersLocations({ locations }));

          return of(
            SearchFiltersActions.getSearchFiltersDivisionsSuccess({
              divisions
            })
          );
        }

        const newParams = {
          ...params,
          status: 'ACTIVE',
          type: 'LEGACY',
          per_page: env.apiRequestPageSize
        };

        return this.apiService.getCompanyDivisions(companyId, newParams).pipe(
          map(result => {
            this.divisionCache.divisions = result;
            return SearchFiltersActions.getSearchFiltersDivisionsSuccess({ divisions: result });
          })
        );
      }),
      catchError(error => {
        return of(SearchFiltersActions.getSearchFiltersDivisionsFailure({ error }));
      })
    );
  });

  getLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchFiltersActions.getSearchFiltersLocations),
      concatLatestFrom(() => [
        this.permissionsService.getDivisionMap(),
        this.permissionsService.getIsZonarUser(),
        this.permissionsService.getCurrentCompanyContext()
      ]),
      concatMap(([action, divisionMap, isZonarUser, currentCompany]) => {
        const { companyId, legacyAccountCode, params } = action;
        const locations = [];
        Object.keys(divisionMap).forEach(divisionId => {
          const division = divisionMap[divisionId];
          if (division.type === 'LEGACY_LOCATION' && division.companyId === currentCompany.id) {
            locations.push(division);
          }
        });
        if (!isZonarUser) {
          this.store.dispatch(SearchFiltersActions.saveSearchFiltersLocations({ locations }));
          return of(SearchFiltersActions.getSearchFiltersLocationsSuccess());
        }
        const newParams = {
          ...params,
          status: 'ACTIVE',
          type: 'LEGACY_LOCATION',
          legacyAccountCode,
          page: 1,
          per_page: env.apiRequestPageSize
        };

        return this.apiService.getCompanyDivisions(companyId, newParams).pipe(
          tap(result => {
            this.store.dispatch(
              SearchFiltersActions.saveSearchFiltersLocations({
                locations: result
              })
            );
          }),
          map(_ => {
            return SearchFiltersActions.getSearchFiltersLocationsSuccess();
          })
        );
      }),
      catchError(error => {
        return of(SearchFiltersActions.getSearchFiltersLocationsFailure({ error }));
      })
    )
  );

  getDivisionsOnSaveEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionsActionTypes.SAVE_ENTITIES),
      filter(action => {
        const { companies } = action;
        return !!companies[0]; // true (continue) if companies[0] is defined (a customer)
      }),
      switchMap(action => {
        const { companies, divisions } = action;
        const companyId = companies[0] as string;
        const params = (divisions as string[]).length > 0 ? { divisionIds: divisions } : {};
        if ((divisions as string[]).length) {
          return of(
            SearchFiltersActions.getSearchFiltersDivisions({
              companyId,
              params: { divisionIds: divisions }
            })
          );
        }
        return of(SearchFiltersActions.getSearchFiltersDivisions({ companyId }));
      })
    )
  );

  getLocationsOnLoadCompaniesAndDivisionsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SearchFiltersActions.SearchFiltersActionTypes.GET_DIVISIONS_SUCCESS,
          SearchFiltersActions.SearchFiltersActionTypes.GET_COMPANIES_SUCCESS
        ),
        concatMap(action =>
          of(action).pipe(
            concatLatestFrom(() => [
              this.store.pipe(select(fromFilters.selectAllFilters)),
              this.store.pipe(select(fromSearchFilters.selectCompaniesLoadingState)),
              this.store.pipe(select(fromSearchFilters.selectDivisionsLoadingState))
            ])
          )
        ),
        filter(([action, filters, companiesLoaded, divisionsLoaded]) => {
          return (
            companiesLoaded === ResourceLoadState.LOAD_SUCCESSFUL &&
            divisionsLoaded === ResourceLoadState.LOAD_SUCCESSFUL
          );
        }),
        map(([action, filters, companiesLoaded, divisionsLoaded]) => {
          const companyId = filters.companies[0].value;
          const division = filters.divisions.find(d => d.companyId === companyId);
          const legacyAccountCode = division?.legacyAccountCode;
          const params = { page: 1, per_page: env.apiRequestPageSize };
          this.store.dispatch(SearchFiltersActions.getSearchFiltersLocations({ companyId, legacyAccountCode, params }));
        })
      ),
    { dispatch: false }
  );

  getLocationsForLimitedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchFiltersActions.SearchFiltersActionTypes.GET_LOCATIONS_FOR_LIMITED_USER),
      concatLatestFrom(() => [
        this.store.select(fromFilters.selectAllFilters),
        this.permissionsService.getDivisionMap()
      ]),
      filter(([action, filters, divMap]) => !!Object.keys(divMap).length),
      switchMap(([action, filters, divisionMap]) => {
        // if state already has locations, clear it out to prevent concatenation of dup locations
        if (filters.locations.length) {
          this.store.dispatch(SearchFiltersActions.clearSearchFiltersLocations());
        }

        const selectedAccountId = action.locations[0];
        const selectedAccount = divisionMap[selectedAccountId];
        const legacyAccountCode = selectedAccount.legacyAccountCode;
        const locationOptions = [];
        let acc = 0;
        let loc = 0;
        Object.keys(divisionMap).forEach(id => {
          if (divisionMap[id].legacyAccountCode === legacyAccountCode && divisionMap[id].type === 'LEGACY_LOCATION') {
            locationOptions.push(divisionMap[id]);
            loc++;
          } else if (divisionMap[id].legacyAccountCode === legacyAccountCode && divisionMap[id].type === 'LEGACY') {
            acc++;
          }
        });
        // regardless if locations has a length, we still want to dispatch the locations array. An empty array disables the locations dropdown
        this.store.dispatch(SearchFiltersActions.saveSearchFiltersLocations({ locations: locationOptions }));
        return of(SearchFiltersActions.getSearchFiltersLocationsSuccess());
      })
    )
  );

  setLimitedUserDivisions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SearchFiltersActions.SearchFiltersActionTypes.GET_DIVISIONS_FOR_LIMITED_USER),
      concatLatestFrom(() => this.permissionsService.getDivisionMap()),
      switchMap(([action, allPermissions]) => {
        const { companyId, divisions } = action;
        const selectedCompanyId = (companyId as any).id;

        const accountOptions = action.divisions
          .map(d => d as any)
          .filter(d => {
            return (d as any).companyId === selectedCompanyId;
          });

        return of(SearchFiltersActions.getSearchFiltersDivisionsSuccess({ divisions: accountOptions }));
      })
    );
  });

  mapSettingToSortParams(settingValue: string): { sortAttribute: SortAttribute; sortOrder: SortOrder } {
    switch (settingValue) {
      case 'ASSET_ASC':
        return { sortAttribute: 'assetName', sortOrder: 'asc' };
      case 'ASSET_DESC':
        return { sortAttribute: 'assetName', sortOrder: 'desc' };
      case 'DRIVER_ASC':
        return { sortAttribute: 'driverName', sortOrder: 'asc' };
      case 'DRIVER_DESC':
        return { sortAttribute: 'driverName', sortOrder: 'desc' };
      case 'OLDEST':
        return { sortAttribute: 'geoEventTs', sortOrder: 'asc' };
      case 'NEWEST':
        return { sortAttribute: 'geoEventTs', sortOrder: 'desc' };
    }
  }
}
