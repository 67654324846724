import { Injectable } from '@angular/core';
import { buildAssetsParams, objectShallowEquality } from '@app/modules/location-client/utilities';
import { LeafletService } from '@app/modules/location/services/leaflet.service';
import { ReverseGeocoderService } from '@app/modules/reverse-geocoder/services/reverse-geocoder.service';
import { AppState } from '@app/store';
import {
  selectAllAssets,
  selectAssetParams,
  selectAssetsLoadState,
  selectChosenAsset
} from '@app/store/asset/selectors/assets.selectors';

import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  skip,
  skipUntil,
  switchMap,
  takeUntil,
  takeWhile
} from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import * as AssetsActions from '../actions/assets.actions';
import { startAssetsPollingOnInitialLoad } from '../actions/assets.actions';
import * as FilterActions from '../../filters/actions/filters.actions';
import { DataDogService } from '@app/services/data-dog.service';
import { LiveAssetQueryService } from '@app/services/live-asset-query.service';
import { AssetsParams, DeviceType } from '@app/modules/location-client/location-api.models';
import { environment } from '@environments/environment';
import { RecentPathService } from '@app/modules/location/services/recent-path.service';
import * as LayoutActions from '@app/store/layout/actions/layout.actions';
import { DetailsSubcontext, ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { HttpCancelService } from '@app/services/http-cancel.service';
import { AssetIoService } from '@app/modules/asset-io/asset-io.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { SelectedAssetService } from '@app/services/selected-asset.service';
import { selectViewSubContext } from '@app/store/layout/selectors/layout.selectors';
import { selectAllFilters, selectDefaultFilter } from '@app/store/filters/selectors/filters.selectors';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';
import { getDistance } from '@app/modules/shared/utilities/utilities';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { UiUtilities } from '@app/services/ui-utilities';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';

@Injectable()
export class AssetsEffects {
  cancelSubject$ = new Subject<boolean>();
  assetsParams: AssetsParams;
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private datadog: DataDogService,
    private leaflet: LeafletService,
    private liveAssetsQuery: LiveAssetQueryService,
    private recentPathService: RecentPathService,
    private httpCancelService: HttpCancelService,
    private geocoder: ReverseGeocoderService,
    private assetIoService: AssetIoService,
    private selectedAssetService: SelectedAssetService,
    private markerIconService: MarkerIconService,
    private uiUtils: UiUtilities,
    private leafletZoneService: LeafletZoneService
  ) {}

  loadAssetsOnCompanySelection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FilterActions.setCurrentCompany),
      distinctUntilChanged(),
      concatLatestFrom(() => [this.store.select(selectAssetParams), this.store.select(selectAllFilters)]),
      switchMap(([action, assetParams, filters]) => {
        const params = buildAssetsParams({ ...assetParams, companyId: action.company.id }, filters);
        this.store.dispatch(AssetsActions.stopAssetsPolling()); // stopping the polling mechanism specifically on company selection to prevent weird context issues.
        return of(AssetsActions.loadAssets({ assetsParams: params }));
      })
    );
  });
  loadAssets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.loadAssets),
      distinctUntilChanged((prev, next) => objectShallowEquality(prev.assetsParams, next.assetsParams)),
      concatLatestFrom(() => this.store.select(selectViewSubContext)),
      filter(([action, viewSubContext]) => {
        // ZTT-1200: viewSubContext needed so that other assets do not load in history, as there were indeterminate times assets would load on map in history view.
        // absent this check for viewSubContext, pins will appear on the map after reloading while in history view and selecting a company
        return (
          action?.assetsParams?.companyId !== null &&
          action.assetsParams?.companyId !== undefined &&
          viewSubContext !== DetailsSubcontext.HISTORY
        );
      }),
      mergeMap(([action, _]) => {
        this.resetSubscriptions();
        this.assetsParams = { ...action.assetsParams };
        return this.liveAssetsQuery.executeAssetQueryWithParams(action.assetsParams).pipe(
          map(assets => {
            return AssetsActions.loadAssetsSuccess({ response: assets });
          }),
          catchError(assetsLoadError => {
            this.datadog.addRumError(assetsLoadError);
            return of(AssetsActions.loadAssetsFailure({ assetsLoadError }));
          })
        );
      })
    );
  });

  loadAssetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadAssetsSuccess),
        concatLatestFrom(() => this.store.select(selectAllFilters)),
        map(([action, selectedFilters]) => {
          if (selectedFilters.filter.zone) {
            this.leaflet.zoomToZone(selectedFilters.zone);
            this.leafletZoneService.highlightSelectedZone(selectedFilters.zone, this.leaflet.map);
          }
          if (action.response?.length >= 0) {
            if (!selectedFilters.filter.zone) this.leaflet.zoomToAssets(action.response);
            this.leaflet.refreshMap(action.response);
            this.store.dispatch(startAssetsPollingOnInitialLoad());
          }
        })
      ),
    { dispatch: false }
  );
  /**
   * This is the assets polling effect
   */
  startAssetsPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.startAssetsPolling),
      concatLatestFrom(() => this.store.select(selectAssetParams)),
      switchMap(([_, assetsParams]) => {
        this.assetsParams = { ...assetsParams };
        return of(AssetsActions.updateAssets({ assetsParams }));
      })
    )
  );

  updateAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.updateAssets),
      // This is the polling mechanism
      concatLatestFrom(() => this.store.select(selectViewSubContext)),
      switchMap(([action, viewSubContext]) => {
        return this.liveAssetsQuery.executeAssetQueryWithParams(action.assetsParams).pipe(
          // additional insurance policy in the front of the pipeline to cut the event if we change params mid-stream.
          takeWhile(() => {
            return (
              viewSubContext === DetailsSubcontext.LIVE && objectShallowEquality(this.assetsParams, action.assetsParams)
            );
          }),
          switchMap(assets => {
            return of(
              AssetsActions.loadAssetsForIntervalSuccess({ response: assets, assetsParams: action.assetsParams })
            );
          }),
          takeUntil(this.httpCancelService.onCancelLocApiPendingRequests()),
          takeUntil(this.cancelSubject$)
        );
      })
    )
  );

  loadAssetsOnIntervalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadAssetsForIntervalSuccess),
      switchMap(action => {
        this.leaflet.refreshMap(action.response);
        return of(action).pipe(
          delay(environment.liveUpdate.pollingInterval),
          switchMap(() => of(AssetsActions.updateAssets({ assetsParams: action.assetsParams })))
        );
      })
    )
  );

  /**
   * Delayed asset polling on initial load.
   */
  startAssetsPollingOnInitialLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.startAssetsPollingOnInitialLoad),
      delay(environment.liveUpdate.pollingInterval),
      map(() => {
        return AssetsActions.startAssetsPolling();
      })
    )
  );

  stopAssetsPolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.stopAssetsPolling),
        map(() => {
          this.resetSubscriptions();
        })
      ),
    { dispatch: false }
  );

  selectLive$ = createEffect(() => {
    // when the application bootstraps, 1x of the setViewSubContext action is fired.
    // at that time, polling has not started, so there is no need to restart it
    // this `skipWhile` prevents 1x unnecessary api call during application bootstrap
    const assetsInitiallyLoaded$ = this.store
      .select(selectAssetsLoadState)
      .pipe(filter(assetsLoadState => assetsLoadState === ResourceLoadState.LOAD_SUCCESSFUL));

    return this.actions$.pipe(
      ofType(LayoutActions.setViewSubContext),
      skipUntil(assetsInitiallyLoaded$),
      filter(({ subContext }) => subContext === DetailsSubcontext.LIVE),
      map(() => {
        return AssetsActions.startAssetsPolling();
      })
    );
  });

  selectHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.setViewSubContext),
      concatLatestFrom(() => this.store.select(selectChosenAsset)),
      filter(
        ([action, selectedAsset]) => action.subContext === DetailsSubcontext.HISTORY && Boolean(selectedAsset?.assetId)
      ),
      mergeMap(([_, selectedAsset]) => {
        this.store.dispatch(AssetsActions.stopAssetsPolling());
        return of(AssetsActions.loadRecentPath({ selectedAsset }));
      })
    )
  );

  selectList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LayoutActions.setViewContext),
        filter(({ context }) => context === ViewContext.LIST),
        skip(1),
        concatLatestFrom(() => this.store.select(selectAllAssets)),
        map(([action, assets]) => {
          this.leaflet.setSelectedAsset(null);
        })
      );
    },
    { dispatch: false }
  );

  selectAssetForLeaflet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadSelectedAsset),
        map(action => action?.selectedAsset),
        map(selectedAsset => {
          if (selectedAsset?.latitude && selectedAsset?.longitude) {
            // this ensures that if a user gets nearby assets, then clicks a new map marker from nearby assets, that new map marker will not have "X miles awy" on the pin
            const asset = {
              ...selectedAsset,
              markerSubtitle: ''
            };
            this.leaflet.setSelectedAsset(asset);
            this.leaflet.zoomToAssets([asset]);
          } else {
            this.leaflet.setSelectedAsset(null);
          }
          this.store.dispatch(AssetsActions.clearNearbyAssets());
        })
      ),
    { dispatch: false }
  );

  loadAssetGeocode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadSelectedAsset),
      filter(({ selectedAsset }) => Boolean(selectedAsset?.latitude && selectedAsset?.longitude)),
      mergeMap(({ selectedAsset }) => {
        return of(
          AssetsActions.loadReverseGeocoding({ latitude: selectedAsset.latitude, longitude: selectedAsset.longitude })
        );
      })
    )
  );

  loadGeocodeAddressOnUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadAssetsForIntervalSuccess),
        concatLatestFrom(() => this.store.select(selectChosenAsset)),
        map(([_, selectedAsset]) => {
          if (selectedAsset?.latitude && selectedAsset?.longitude) {
            this.store.dispatch(
              AssetsActions.loadReverseGeocoding({
                latitude: selectedAsset.latitude,
                longitude: selectedAsset.longitude
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  loadReverseGeocoding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadReverseGeocoding),
      mergeMap(({ latitude, longitude }) =>
        this.geocoder.getReverseGeocode(latitude, longitude).pipe(
          map(res => {
            const data = res.data[0];
            return AssetsActions.loadReverseGeocodingSuccess({ data });
          }),
          catchError(error => {
            this.datadog.addRumError(error);
            return of(AssetsActions.loadReverseGeocodingError({ error }));
          })
        )
      )
    )
  );

  loadNearbyAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadNearbyAssets),
      concatLatestFrom(() => [this.store.select(selectAssetParams), this.store.select(selectDefaultFilter)]),
      mergeMap(([{ selectedAsset }, assetsParams, defaultFilter]) => {
        let params;
        if (assetsParams) {
          params = assetsParams;
        } else {
          // we go here in case a user has directly gone to /assets/:assetId, in this case,they would not yet have assetsParams in state, which prevented the nearby assets list from loading
          params = {
            companyId: defaultFilter.company.id
          };
        }

        const nearbyAssetsParams: AssetsParams = {
          ...params,
          active: true,
          circle: [selectedAsset.latitude, selectedAsset.longitude, environment.nearbyAssets.searchRadiusMeters]
        };
        delete nearbyAssetsParams.searchTerms;
        delete nearbyAssetsParams.powerOn;
        return this.liveAssetsQuery.executeAssetQueryWithParams(nearbyAssetsParams).pipe(
          map(response => {
            response = response.filter(asset => asset.assetId !== selectedAsset.assetId);
            const nearbyAssets = this.addMarkerDataToNearbyAssets(response, selectedAsset);
            return AssetsActions.loadNearbyAssetsSuccess({ response: nearbyAssets });
          }),
          catchError(nearbyAssetsLoadError => {
            this.datadog.addRumError(nearbyAssetsLoadError);
            return of(AssetsActions.loadNearbyAssetsFailure({ nearbyAssetsLoadError }));
          }),
          takeUntil(this.httpCancelService.onCancelLocApiPendingRequests())
        );
      })
    )
  );

  addMarkerDataToNearbyAssets(nearbyAssets: ViewableAsset[], selectedAsset: ViewableAsset): ViewableAsset[] {
    const fromLatLng = [selectedAsset?.latitude, selectedAsset?.longitude];
    return nearbyAssets
      ?.map(asset => {
        const distance = this.uiUtils.convertDistance(getDistance(fromLatLng, [asset.latitude, asset.longitude]));
        return {
          ...asset,
          distanceFromSelectedAsset: distance,
          iconUrl: this.markerIconService.fetchAssetsListIconUrl(asset),
          className: this.markerIconService.fetchAssetClassName(asset),
          subTitle: this.uiUtils.assetSubtitle(asset),
          sidebarMessage: this.uiUtils.assetSidebarMessage(distance),
          markerSubtitle: this.uiUtils.assetSidebarMessage(distance)
        };
      })
      .sort((a, b) => a.distanceFromSelectedAsset - b.distanceFromSelectedAsset)
      .slice(0, environment.nearbyAssets.assetsToReturn);
  }

  addTripDataToPathFeatures(response, deviceType) {
    let inSegment = false;
    const featureCount = response.pointData.features.length;
    let startOdo;
    let startTime;
    let lastTripEndTime;
    let segIdx = 0;
    const isZTrak = deviceType === DeviceType.ZTRAK;
    response.pointData.features.forEach((f, i: number) => {
      f.tripStart = false;
      f.tripEnd = false;
      f.falsePowerEvent = false;
      f.isZTrak = isZTrak;
      if (!inSegment && f.properties.powerOn && !f.falsePowerEvent) {
        inSegment = true;
        f.tripStart = true;
        startOdo = f.properties.odometer;
        startTime = moment(f.properties.timeStamp);
        if (segIdx > 0) {
          f.timeSincePreviousTrip = moment(f.properties.timeStamp).diff(moment(lastTripEndTime));
        }
      }
      if (inSegment && (!f.properties.powerOn || i === featureCount - 1)) {
        if (
          i < featureCount - 1 &&
          moment(response.pointData.features[i + 1].properties.timeStamp).diff(moment(f.properties.timeStamp)) <
            environment.minimumTripMS &&
          i > 1 &&
          response.pointData.features[i - 1].properties.powerOn
        ) {
          f.falsePowerEvent = true;
          response.pointData.features[i + 1].falsePowerEvent = true;
        } else {
          f.tripEnd = true;
          lastTripEndTime = f.properties.timeStamp;
          inSegment = false;
          f.tripDistance = f.properties.odometer - startOdo;
          f.tripDuration = moment(f.properties.timeStamp).diff(startTime);
          segIdx++;
        }
      }
    });
    response.summary.powerOffCount = response.pointData.features[featureCount - 1].properties.powerOn
      ? segIdx - 1
      : segIdx;
    return response;
  }

  enrichPathFeatureProperties(response) {
    return {
      ...response,
      pointData: {
        ...response.pointData,
        features: response.pointData.features.map(feature => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              inputsFormatted: this.assetIoService.formatPathIos(feature.properties.inputs)
            }
          };
        })
      }
    };
  }

  enrichPathSummary(response) {
    const features = response.pointData.features;

    if (!features.length) {
      return response;
    }

    const ioCounts = [];

    features.forEach(feature => {
      const formattedInputs = this.assetIoService.formatPathIos(feature.properties.inputs);
      formattedInputs.forEach((io, i) => {
        if (io.change) {
          ioCounts[i] = ioCounts[i] || { label: io.label, count: 0 };
          ioCounts[i].count += 1;
        }
      });
    });

    return {
      ...response,
      summary: { ...response.summary, ioCounts }
    };
  }

  loadRecentPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsActions.loadRecentPath),
      switchMap(({ selectedAsset }) => {
        const startTimeBacktoZero = moment(selectedAsset.startTime).startOf('day').toDate();
        const endTime = moment(startTimeBacktoZero).add(1, 'day').toDate();

        return this.recentPathService.fetchRecentPath(selectedAsset, startTimeBacktoZero, endTime).pipe(
          map(response => {
            this.leaflet.clearRecentPathLayer();
            if (response.pointData?.features?.length > 0) {
              response.pointData.features[0].isFirst = true;
              response.pointData.features[response.pointData.features.length - 1].isLast = true;
              return this.addTripDataToPathFeatures(
                response,
                this.uiUtils.gpssnToGpsType(selectedAsset.legacyAttributes.gpssn)
              );
            }
            return response;
          }),
          map(response => {
            return this.enrichPathFeatureProperties(response);
          }),
          map(response => {
            return this.enrichPathSummary(response);
          }),
          map(response => {
            return AssetsActions.loadRecentPathSuccess({ response: response });
          }),

          catchError(recentPathLoadError => {
            this.datadog.addRumError(recentPathLoadError);
            return of(AssetsActions.loadRecentPathFailure({ recentPathLoadError }));
          }),
          takeUntil(this.httpCancelService.onCancelPathApiPendingRequests())
        );
      })
    )
  );

  loadRecentPathSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.loadRecentPathSuccess),
        map(action => {
          const recentPath = action.response.pointData;
          this.leaflet.showRecentPathLayer(recentPath);
        })
      ),
    { dispatch: false }
  );

  clearRecentPath$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssetsActions.clearRecentPath),
        map(() => {
          this.leaflet.clearRecentPathLayer();
        })
      ),
    { dispatch: false }
  );

  loadSelectedAssetById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssetsActions.loadSelectedAssetById),
      switchMap(({ assetId }) => {
        return this.selectedAssetService.getSelectedAssetById(assetId).pipe(
          first(),
          map(asset => {
            return AssetsActions.loadSelectedAsset({ selectedAsset: asset });
          }),
          catchError(assetLoadFailure => {
            this.datadog.addRumError(new Error(assetLoadFailure));
            return of(AssetsActions.loadSelectedAssetFailure({ failure: assetLoadFailure }));
          })
        );
      })
    );
  });

  resetSubscriptions() {
    this.assetsParams = null;
    this.cancelSubject$.next(true);
    this.httpCancelService.cancelLocApiPendingRequests();
  }
}
