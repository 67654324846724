import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, mergeMap, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { getDriverName, validGpsData } from '@app/modules/shared/utilities/utilities';
import { UiUtilities } from '@app/services/ui-utilities';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { environment as env } from '@environments/environment';
import { TimersService } from '@app/services/timers.service';
import { UntypedFormControl } from '@angular/forms';
import { DataDogService } from '@app/services/data-dog.service';
import { LoadingAnimationService } from '@app/services/loading-animation.service';
import { DetailsSubcontext, ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { SettingsApiService } from '@app/services/settings-api.service';
import { MapSettings } from '@app/modules/location/models/settings.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetListComponent implements OnInit, OnDestroy {
  @Output()
  hoverOut: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  hoverOver: EventEmitter<object> = new EventEmitter<object>();

  onDestroy$ = new Subject<void>();
  assets: ViewableAsset[] = [];
  assetCount: number;
  listTitle: string;
  assetCountResults: string;
  intervalTimer;

  sortingControl = new UntypedFormControl();
  sortingOptions = [
    { viewValue: 'Asset (A-Z)', sortKey: 'assetName', sortAsc: true },
    { viewValue: 'Asset (Z-A)', sortKey: 'assetName', sortAsc: false },
    { viewValue: 'Driver (A-Z)', sortKey: 'driverName', sortAsc: true },
    { viewValue: 'Driver (Z-A)', sortKey: 'driverName', sortAsc: false },
    { viewValue: 'Newest updates', sortKey: 'geoEventTs', sortAsc: false },
    { viewValue: 'Oldest updates', sortKey: 'geoEventTs', sortAsc: true }
  ];
  sortingDefaultValue = this.sortingOptions[0];
  sortKey = this.sortingDefaultValue.sortKey;
  sortAsc = this.sortingDefaultValue.sortAsc;
  previousSortKey = this.sortKey;
  previousSortAsc = this.sortAsc;
  // If you update vsItemSize, you must also update the asset-list-item-card min-height in asset-list.theme.scss
  vsItemSize = 89; // vertical size in px of asset-list-item.
  vsMinBufferPx = this.vsItemSize * 20;
  vsMaxBufferPx = this.vsItemSize * 40;
  skeletonMaxCount = 15;
  currentAssetListLoadState = ResourceLoadState.INITIAL;
  translated: any;

  constructor(
    private locationFacade: LocationFacade,
    public markerIconService: MarkerIconService,
    private changeDetector: ChangeDetectorRef,
    private timersService: TimersService,
    private dataDog: DataDogService,
    private loadingAnimationService: LoadingAnimationService,
    private settingsService: SettingsApiService,
    public translations: Translations,
    public translateService: TranslateService,
    private uiUtils: UiUtilities
  ) {}
  hideMobileSidenavHeader: HTMLElement;

  ngOnInit(): void {
    this.translations.translationsLoadState
      .pipe(
        filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL),
        mergeMap(_ => {
          return of(
            this.translateService.instant([
              this.translations.appAssetList.sortBy.assetAsc,
              this.translations.appAssetList.sortBy.assetDesc,
              this.translations.appAssetList.sortBy.driverAsc,
              this.translations.appAssetList.sortBy.driverDesc,
              this.translations.appAssetList.sortBy.oldestUpdates,
              this.translations.appAssetList.sortBy.newestUpdates
            ])
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(translations => {
        this.translated = translations;
        this.sortingOptions = [
          {
            viewValue: this.translated[this.translations.appAssetList.sortBy.assetAsc],
            sortKey: 'assetName',
            sortAsc: true
          },
          {
            viewValue: this.translated[this.translations.appAssetList.sortBy.assetDesc],
            sortKey: 'assetName',
            sortAsc: false
          },
          {
            viewValue: this.translated[this.translations.appAssetList.sortBy.driverAsc],
            sortKey: 'driverName',
            sortAsc: true
          },
          {
            viewValue: this.translated[this.translations.appAssetList.sortBy.driverDesc],
            sortKey: 'driverName',
            sortAsc: false
          },
          {
            viewValue: this.translated[this.translations.appAssetList.sortBy.newestUpdates],
            sortKey: 'geoEventTs',
            sortAsc: false
          },
          {
            viewValue: this.translated[this.translations.appAssetList.sortBy.oldestUpdates],
            sortKey: 'geoEventTs',
            sortAsc: true
          }
        ];
      });
    this.sortingControl.setValue(this.sortingDefaultValue);
    this.locationFacade.setViewContext(ViewContext.LIST);
    this.locationFacade.setViewSubContext(DetailsSubcontext.LIVE);

    this.locationFacade.getAllFilters().subscribe(({ filter }) => {
      const sortAsc = filter.sortOrder === 'asc' ? true : false;
      const sortKey = filter.sortAttribute;
      const selectedOption = this.sortingOptions.find(o => o.sortAsc === sortAsc && o.sortKey === sortKey);
      this.sortingControl.setValue(selectedOption, { emitEvent: false });
      this.sortKey = sortKey;
      this.sortAsc = sortAsc;
      this.assets = this.sortAssets(this.assets);
    });

    this.locationFacade
      .getAllAssets()
      .pipe(
        withLatestFrom(
          of(
            this.translateService.instant([
              this.translations.appAssetList.assetList,
              this.translations.appAssetList.results,
              this.translations.appAssetList.noResultsFound
            ])
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([assets, translations]) => {
        this.assets = assets.map(asset => ({
          ...asset,
          iconUrl: this.markerIconService.fetchAssetsListIconUrl(asset),
          subTitle: this.uiUtils.assetSubtitle(asset),
          className: this.markerIconService.fetchAssetClassName(asset),
          sidebarMessage: this.getTimeAgoString(asset),
          driverName: getDriverName(asset)
        }));
        this.assets = this.sortAssets(this.assets);
        this.assetCount = this.assets.length;

        if (this.assetCount && typeof this.assetCount === 'number') {
          this.listTitle = translations[this.translations.appAssetList.assetList];
          this.assetCountResults = `(${this.assetCount} ${translations[this.translations.appAssetList.results]})`;
        } else {
          this.listTitle = translations[this.translations.appAssetList.noResultsFound];
        }
        this.changeDetector.detectChanges();
      });

    this.sortingControl.valueChanges.subscribe(({ sortKey, sortAsc }) => {
      const sorting = {
        sortOrder: sortAsc ? 'asc' : 'desc',
        sortAttribute: sortKey === 'assetName' ? 'assetName' : sortKey === 'geoEventTs' ? 'geoEventTs' : 'driverName'
      };
      this.locationFacade.applySorting(sorting);
      this.saveSortSetting(sortKey, sortAsc);
    });

    // set interval timer to force update of last updated message
    this.intervalTimer = this.timersService.intervalTimer(() => {
      this.assets.forEach(a => (a.sidebarMessage = this.getTimeAgoString(a)));
      this.changeDetector.detectChanges();
    }, env.dataUpdateMessages.updateInterval * 60000);

    if (window.location.hostname.includes('vdo-native-app')) {
      this.hideMobileSidenavHeader = document.getElementById('sidenav-header');
      if (this.hideMobileSidenavHeader) {
        this.hideMobileSidenavHeader.style.display = 'block';
      }
    }
  }

  sortAssets(assets: ViewableAsset[]) {
    if (this.sortKey == 'driverName') {
      return [...assets].sort((a, b) => this.sortCallbackDriverName(a, b));
    }
    return [...assets].sort((a, b) => this.sortCallback(a, b, this.sortKey));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    clearInterval(this.intervalTimer);
  }

  loadComplete(): Observable<boolean> {
    if (this.loadingAnimationService.shouldShowAssetsLoadingAnimations) {
      return this.locationFacade.getAssetsLoadState().pipe(
        switchMap(loadState => {
          if (
            this.currentAssetListLoadState === ResourceLoadState.LOADING &&
            loadState === ResourceLoadState.LOAD_SUCCESSFUL
          ) {
            this.dataDog.newRumTiming('asset_list_loaded');
          }
          this.currentAssetListLoadState = loadState;
          const loadingComplete =
            loadState === ResourceLoadState.LOAD_SUCCESSFUL || loadState === ResourceLoadState.LOAD_FAILURE;

          return of(loadingComplete);
        }),
        takeUntil(this.onDestroy$)
      );
    }
    return of(true);
  }

  sortCallback(a, b, key) {
    a = a[key]?.toLowerCase();
    b = b[key]?.toLowerCase();

    if ((!a && !b) || a === b) {
      return 0;
    }
    if (!a || a < b) {
      return this.sortAsc ? -1 : 1;
    }
    if (!b || a > b) {
      return this.sortAsc ? 1 : -1;
    }
  }

  sortCallbackDriverName(a, b) {
    a = a.driverName?.toLowerCase();
    b = b.driverName?.toLowerCase();
    if (a === b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (a < b) {
      return this.sortAsc ? -1 : 1;
    }
    if (a > b) {
      return this.sortAsc ? 1 : -1;
    }
  }

  trackFn(index, value) {
    return value.assetId;
  }

  getTimeAgoString(asset: ViewableAsset): string {
    if (validGpsData(asset)) {
      return this.uiUtils.getTimeAgoString(asset.geoEventTs, false);
    }
    return '';
  }

  mapSortParamsToSetting(sortKey, sortAsc) {
    switch (sortKey) {
      case 'assetName':
        return sortAsc ? 'ASSET_ASC' : 'ASSET_DESC';
      case 'driverName':
        return sortAsc ? 'DRIVER_ASC' : 'DRIVER_DESC';
      case 'geoEventTs':
        return sortAsc ? 'OLDEST' : 'NEWEST';
    }
  }

  saveSortSetting(sortKey: string, sortAsc: boolean): void {
    if (sortKey !== this.previousSortKey || sortAsc !== this.previousSortAsc) {
      const settingChoice = this.mapSortParamsToSetting(sortKey, sortAsc);
      this.previousSortAsc = sortAsc;
      this.previousSortKey = sortKey;
      this.settingsService.saveSetting(MapSettings.MAP_SORT_ORDER, settingChoice).pipe(take(1)).subscribe();
    }
  }
}
